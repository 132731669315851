import { sortAvailableOdds } from "../../components/utility/AvailableOddsMapSorting";

const initialState = {
  isAuthenticated: false,
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
  matches: [],
  availableSports: null,
  tournamentMatchMap: null,
  availableTournamentsMapBySport: null,
  oddCountsByMatchMap: null,
  dropDownCount: 5,
  availableDetailtOddsMap: null,
  availableOddsMap: null,
  odds: null,
  error: null,
};

export const LiveMatchReducer6Hrs = (state = initialState, action) => {
  switch (action.type) {
    case "LIVEMATCHES_SUCCESS_6HRS":
      const sortedAvailableOddsMap = sortAvailableOdds(action.payload.data.availableOddsMap);
      return {
        ...state,
        isAuthenticated: true,
        totalItems: action.payload.data.totalItems,
        totalPages: action.payload.data.totalPages,
        currentPage: action.payload.data.currentPage,
        dropDownCount: action.payload.data.dropDownCount,
        matches: action.payload.data.matches,
        availableSports: action.payload.data.availableSports,
        tournamentMatchMap: action.payload.data.tournamentMatchMap,
        availableTournamentsMapBySport: action.payload.data.availableTournamentsMapBySport,
        oddCountsByMatchMap: action.payload.data.oddCountsByMatchMap,
        availableDetailtOddsMap: action.payload.data.availableDetailtOddsMap,
        availableOddsMap: sortedAvailableOddsMap,
        odds: action.payload.data.odds,
        error: null,
      };

    case "LIVEMATCHES_SUCCESS_ADD_6HRS": {
      const sortedAvailableOddsMap = sortAvailableOdds({
        ...state.availableOddsMap,
        ...action.payload.data.availableOddsMap,
      });
      return {
        ...state,
        isAuthenticated: true,
        totalItems: action.payload.data.totalItems,
        totalPages: action.payload.data.totalPages,
        currentPage: action.payload.data.currentPage,
        dropDownCount: action.payload.data.dropDownCount,
        matches: [...state.matches, ...action.payload.data.matches],
        availableSports: { ...state.availableSports, ...action.payload.data.availableSports },
        tournamentMatchMap: { ...state.tournamentMatchMap, ...action.payload.data.tournamentMatchMap },
        availableTournamentsMapBySport: {
          ...state.availableTournamentsMapBySport,
          ...action.payload.data.availableTournamentsMapBySport,
        },
        oddCountsByMatchMap: { ...state.oddCountsByMatchMap, ...action.payload.data.oddCountsByMatchMap },
        availableDetailtOddsMap: { ...state.availableDetailtOddsMap, ...action.payload.data.availableDetailtOddsMap },
        availableOddsMap: sortedAvailableOddsMap,
        odds: { ...state.odds, ...action.payload.data.odds },
        error: null,
      };
    }
    case "LIVEMATCHES_FAILURE_6HRS":
      return {
        ...state,
        isAuthenticated: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
