import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { FilteredHomeApi } from "../../store/actions/filteredHomeAction";
import { MobileGenericMainPageBody } from "../Generic/GenericBody";

export const FilteredHome = ({ showDetail, inMain = false, body, header }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FilteredHomeApi(body));
  }, [dispatch, body]);

  return <MobileGenericMainPageBody type={"filteredMatches"} showDetail={showDetail} />;
};
