import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { TopMatchesApi } from "../../store/actions/top_matches";
import { MobileGenericMainPageBody } from "../Generic/GenericBody";

export const TopEvents = ({}) => {
  const dispatch = useDispatch();
  const showDetail = useSelector((state) => state?.homereducer?.showDetail);

  useEffect(() => {
    dispatch(
      TopMatchesApi({
        pageNumber: 1,
        pageSize: 100,
      })
    );
  }, [dispatch]);

  return <MobileGenericMainPageBody type={"topmatches"} showDetail={showDetail} />;
};
