export const MatchSkeleton = () => {
  return (
    <div role="status" className="space-y-4 animate-pulse max-w-lg">
      <div className="flex flex-col">
        <div className="flex items-center space-x-4 h-8 pl-3 pt-2">
          <div className="h-8 w-20 bg-gray-300 rounded-full"></div>
          <div className="h-8 w-20 bg-gray-300 rounded-full"></div>
          <div className="h-8 w-20 bg-gray-300 rounded-full"></div>
          <div className="h-8 w-20 bg-gray-300 rounded-full"></div>
        </div>
        {[...Array(5)].map((_, index) => (
          <div key={index} className="flex pt-2">
            <div className="flex flex-col pr-2">
              <div className="h-8 w-20 bg-gray-300 rounded-full  pb-2" />
            </div>
            {/* {/* <div className="h-3 w-40 bg-gray-200 rounded-full bg-red-500" /> */}
            <div className="pr-5 h-8 w-20 bg-gray-300 rounded-full" />
            <div className="h-8 w-20 bg-gray-300 rounded-full" />
            <div className="h-8 w-20 bg-gray-300 rounded-full" />
            <div className="h-8 w-10 bg-gray-200 rounded-full" />
          </div>
        ))}
      </div>

      <span className="sr-only">Loading...</span>
    </div>
  );
};
