import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { LastMinApi } from "../../store/actions/last_minActions";
import { GenericMainPageBody } from "../Generic/GenericBody";
export const Last_Min = ({ showDetail }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      LastMinApi({
        pageNumber: 1,
        pageSize: 10,
      })
    );
  }, [dispatch]);
  return (
    <GenericMainPageBody
      allEventsLink={"/last-minutes"}
      className={"md:grid hidden"}
      type={"lastmin"}
      showDetail={showDetail}
      title={"Last Minutes"}
    />
  );
};
