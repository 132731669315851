// src/layouts/MainLayout.js
import React from "react";
import Header from "../../components/Headers/Header";
import Footer from "../../components/Footer";
import { BottomAppbar } from "../../components/BottomAppbar";
// import { Outlet } from 'react-router-dom';

const BetGuideLayout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen font-Source-Code-Pro ">
      <Header />
      <div className="flex flex-grow md:mt-14">
        <main className="flex-1 overflow-x-auto ">{children}</main>
      </div>
      <BottomAppbar />
      <Footer />
    </div>
  );
};

export default BetGuideLayout;
