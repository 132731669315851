import React from "react";
import Footer from "../../components/Footer";
import { BottomAppbar } from "../../components/BottomAppbar";
import { FootballMenu } from "../pages/default/footballMenu";

const FootballMenus = ({}) => {
  return (
    <div className="flex flex-col min-h-screen font-Source-Code-Pro ">
      <div className="flex flex-grow md:mt-14 h-[100vh]">
        <main className="flex-1 overflow-x-auto bg-custom ">
          <FootballMenu />
        </main>
      </div>
      <BottomAppbar />
      <Footer />
    </div>
  );
};

export default FootballMenus;
