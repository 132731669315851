import { memo, useEffect, useRef, useState } from "react";
import { shirt1, shirt2 } from "../../assets/images";
import { TbRectangleVerticalFilled } from "react-icons/tb";
import { useSelector } from "react-redux";

export const GenericTeamNames = memo(({ home_team_name, home_team_logo, away_team_name, away_team_logo, matchId }) => {
  const redCards = useSelector((state) => state?.livematches?.redCard?.[matchId]);
  const yellowCard = useSelector((state) => state?.livematches?.yellowCard?.[matchId]);
  const [changeYellowCard, setChangeYellowCard] = useState(false);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!changeYellowCard) {
      return;
    }
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setChangeYellowCard(true);
    const timeoutId = setTimeout(() => {
      setChangeYellowCard(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [yellowCard]);

  return (
    <div className="flex flex-col place-content-center ml-4 ">
      <div className="flex items-center gap-2 truncate">
        <img
          className="text-center item-center"
          src={home_team_logo ? home_team_logo : shirt1}
          alt={home_team_name}
          width={15}
          height={15}
        />
        <span className="text-sm">
          {home_team_name.length > 10 ? home_team_name.substring(0, 20) + "..." : home_team_name}
        </span>
        <span className="flex items-center gap-1">
          {Array.from({ length: redCards?.home }).map((_, index) => (
            <TbRectangleVerticalFilled key={index} className="text-red-700 text-sm" />
          ))}
          {changeYellowCard && <TbRectangleVerticalFilled className="text-yellow-400 text-sm" />}
        </span>
      </div>
      <div className="flex items-center gap-2 truncate">
        <img
          className="text-center item-center"
          src={away_team_logo ? away_team_logo : shirt2}
          alt={away_team_name}
          width={15}
          height={15}
        />
        <span className="text-sm">{away_team_name}</span>
        <span className="flex items-center gap-1">
          {Array.from({ length: redCards?.away }).map((_, index) => (
            <TbRectangleVerticalFilled key={index} className="text-red-700 text-sm" />
          ))}
        </span>
      </div>
    </div>
  );
});
