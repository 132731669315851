// src/layouts/MainLayout.js
import React from "react";
import Header from "../../components/Headers/Header";
import { RightSidebar } from "../../components/Sidebar/RightSidebar";
import { BottomAppbar } from "../../components/BottomAppbar";
import { useState } from "react";
const LiveBattingLayout = ({ children }) => {
  const [headerTitle, setHeaderTitle] = useState("Football");

  return (
    <div className="flex flex-col overflow-visible scrollbar-hide min-h-screen font-Source-Code-Pro ">
      <Header title={headerTitle} />

      <div className="flex flex-grow md:mt-7">
        <main className="flex-1 bg-custom md:pl-3 ">{React.cloneElement(children, { setHeaderTitle })}</main>
        <RightSidebar />
      </div>
      <BottomAppbar />
    </div>
  );
};

export default LiveBattingLayout;
