import { combineReducers } from "redux";
import authReducer from "./authReducer";
import authRegistrationReducer from "./authRegistrationReducer";
import { MatchReducer } from "./ApiReducers";
import { ApiSportsReducer } from "./apisports";
import sidebarReducer from "./sidebarReducer";
import homeReducer from "./homeReducers";
import { TopMatchReducer } from "./topmatches";
import { LiveMatchReducer } from "./liveMatchesReducer";
import { TopWinnerReducer } from "./topWinnerReducer";
import { TopCompetitionReducer } from "./topCompetitionReducer";
import { FilteredHomeReducer } from "./filteredHomeReducer";
import { CalculateBetSlipReducer } from "./calculateBetSlipReducer";
import { LastMinReducer } from "./lastMinReducer";
import { PlaceBetSlipReducer } from "./placeBetSlipReducer";
import { LiveMatchReducer3Hrs } from "./liveMatchesReducer3Hrs";
import { LiveMatchReducer6Hrs } from "./liveMatchesReducer6Hrs";

const rootReducer = combineReducers({
  auth: authReducer,
  authreg: authRegistrationReducer,
  apireducer: MatchReducer,
  apisport: ApiSportsReducer,
  sidebar: sidebarReducer,
  homereducer: homeReducer,
  filteredMatches: FilteredHomeReducer,
  calculateBetSlip: CalculateBetSlipReducer,
  topmatches: TopMatchReducer,
  lastmin: LastMinReducer,
  livematches: LiveMatchReducer,
  livematches3Hrs: LiveMatchReducer3Hrs,
  livematches6Hrs: LiveMatchReducer6Hrs,
  topWinner: TopWinnerReducer,
  topCompetition: TopCompetitionReducer,
  placeBetReducer: PlaceBetSlipReducer,
});

export default rootReducer;
