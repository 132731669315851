import { sortAvailableOdds } from "../../components/utility/AvailableOddsMapSorting";
import { LASTMIN_FAILURE, LASTMIN_SUCCESS } from "../actions/actiontypes";

const initialState = {
  isAuthenticated: false,
  totalItems: 0,
  totalPages: 0,
  currentPage: 1,
  matches: [],
  availableSports: null,
  tournamentMatchMap: null,
  availableTournamentsMapBySport: null,
  oddCountsByMatchMap: null,
  dropDownCount: 5,
  availableDetailtOddsMap: null,
  availableOddsMap: null,
  odds: null,
  error: null,
};

export const LastMinReducer = (state = initialState, action) => {
  switch (action.type) {
    case LASTMIN_SUCCESS: {
      const sortedAvailableOddsMap = sortAvailableOdds(action.payload.data.availableOddsMap);
      return {
        ...state,
        isAuthenticated: true,
        totalItems: action.payload.data.totalItems,
        totalPages: action.payload.data.totalPages,
        currentPage: action.payload.data.currentPage,
        dropDownCount: action.payload.data.dropDownCount,
        matches: action.payload.data.matches,
        availableSports: action.payload.data.availableSports,
        tournamentMatchMap: action.payload.data.tournamentMatchMap,
        availableTournamentsMapBySport: action.payload.data.availableTournamentsMapBySport,
        oddCountsByMatchMap: action.payload.data.oddCountsByMatchMap,
        availableDetailtOddsMap: action.payload.data.availableDetailtOddsMap,
        availableOddsMap: sortedAvailableOddsMap,
        odds: action.payload.data.odds,
        error: null,
      };
    }
    case LASTMIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
