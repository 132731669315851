import { isEqual } from "lodash";
import { sortAvailableOdds } from "../../components/utility/AvailableOddsMapSorting";
import {
  LIVEMATCHES_FAILURE,
  LIVEMATCHES_REMOVE,
  LIVEMATCHES_SUCCESS,
  LIVEMATCHES_WEBSOCKET_UPDATE_MATCHES,
  LIVEMATCHES_WEBSOCKET_UPDATE_MATCHES_SCORE,
  LIVEMATCHES_WEBSOCKET_UPDATE_ODDS_1,
} from "../actions/actiontypes";

const initialState = {
  isAuthenticated: false,
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
  matches: [],
  availableSports: null,
  tournamentMatchMap: null,
  availableTournamentsMapBySport: null,
  oddCountsByMatchMap: null,
  dropDownCount: 5,
  availableDetailtOddsMap: null,
  availableOddsMap: null,
  scores: null,
  redCard: {},
  yellowCard: {},
  odds: null,
  error: null,
  selectedOdds: [],
  matchesTimer: null,
  goals: null,
};

export const LiveMatchReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIVEMATCHES_SUCCESS:
      const sortedAvailableOddsMap = sortAvailableOdds(action.payload.data.availableOddsMap);
      const initialScore = action.payload.data.matches.reduce((acc, match) => {
        const scoreList = match.scores_map.find(([key]) => key === "ft")[1].scoreList;
        acc[match.id] = {
          homeTotalScore: scoreList[0],
          awayTotalScore: scoreList[1],
          periods: match.scores_map
            .filter(([key]) => key.startsWith("p"))
            .map(([_, value]) => ({
              homeScore: value.scoreList[0],
              awayScore: value.scoreList[1],
            })),
        };

        return acc;
      }, {});
      const matchesTimers = action.payload.data.matches.reduce((acc, webMatch) => {
        if (webMatch.timer) {
          if (webMatch.sport_id === 1 && webMatch.period !== 5) {
            const asd = JSON.parse(webMatch.timer);
            const timer = Number(Math.ceil(asd[3] / 60));
            if (timer > 45 && webMatch.period === 35) {
              acc[webMatch.id] = "45′+" + (timer - 45);
            } else if (timer > 90 && webMatch.period === 36) {
              acc[webMatch.id] = "90′+" + (timer - 90);
            } else {
              acc[webMatch.id] = timer + 1 + "′";
            }
          } else {
            acc[webMatch.id] = webMatch.period_name;
          }
        }
        return acc;
      }, {});
      const intialGoals = action.payload.data.matches.reduce((acc, match) => {
        const scoreList = match.scores_map.find(([key]) => key === "ft")[1].scoreList;
        acc[match.id] = Number(scoreList[0]) + Number(scoreList[1]);
        return acc;
      });

      return {
        ...state,
        isAuthenticated: true,
        totalItems: action.payload.data.totalItems,
        totalPages: action.payload.data.totalPages,
        currentPage: action.payload.data.currentPage,
        dropDownCount: action.payload.data.dropDownCount,
        matches: action.payload.data.matches,
        availableSports: action.payload.data.availableSports,
        tournamentMatchMap: action.payload.data.tournamentMatchMap,
        availableTournamentsMapBySport: action.payload.data.availableTournamentsMapBySport,
        oddCountsByMatchMap: action.payload.data.oddCountsByMatchMap,
        availableDetailtOddsMap: action.payload.data.availableDetailtOddsMap,
        availableOddsMap: sortedAvailableOddsMap,
        odds: action.payload.data.odds,
        oddCount: action.payload.data.odds.length,
        scores: initialScore,
        matchesTimer: matchesTimers,
        goals: intialGoals,
        error: null,
      };

    case "LIVEMATCHES_SUCCESS_ADD": {
      const sortedAvailableOddsMap = sortAvailableOdds({
        ...state.availableOddsMap,
        ...action.payload.data.availableOddsMap,
      });
      const newMatches = action.payload.data.matches.filter(
        (match) => !state.matches.some((existingMatch) => existingMatch.id === match.id)
      );
      const matchesState = [...state.matches, ...newMatches];

      const newScores = matchesState.reduce((acc, match) => {
        const scoreList = match.scores_map.find(([key]) => key === "ft")[1].scoreList;
        acc[match.id] = {
          homeTotalScore: scoreList[0],
          awayTotalScore: scoreList[1],
          periods: match.scores_map
            .filter(([key]) => key.startsWith("p"))
            .map(([_, value]) => ({
              homeScore: value.scoreList[0],
              awayScore: value.scoreList[1],
            })),
        };

        return acc;
      }, {});

      const newMatchesTimers = matchesState.reduce((acc, webMatch) => {
        if (webMatch.timer) {
          if (webMatch.sport_id === 1 && webMatch.period !== 5) {
            const asd = JSON.parse(webMatch.timer);
            const timer = Number(Math.ceil(asd[3] / 60));
            if (timer > 45 && webMatch.period === 35) {
              acc[webMatch.id] = "45′+" + (timer - 45);
            } else if (timer > 90 && webMatch.period === 36) {
              acc[webMatch.id] = "90′+" + (timer - 90);
            } else {
              acc[webMatch.id] = timer + 1 + "′";
            }
          } else {
            acc[webMatch.id] = webMatch.period_name;
          }
        }
        return acc;
      }, {});

      const newTournament = Object.keys(action.payload.data.availableTournamentsMapBySport).reduce((acc, sport) => {
        const filteredTournaments = action.payload.data.availableTournamentsMapBySport[sport].filter((item) => {
          return !Object.values(state.availableTournamentsMapBySport).some((k) =>
            k.some((test) => test.id === item.id)
          );
        });
        if (filteredTournaments.length > 0) {
          acc[sport] = filteredTournaments;
        }
        return acc;
      }, {});

      const tournamentState = Object.keys(state.availableTournamentsMapBySport).reduce((acc, key) => {
        const exists = newTournament?.[key];
        if (exists) {
          acc[key] = [...state.availableTournamentsMapBySport[key], ...exists];
        } else {
          acc[key] = state.availableTournamentsMapBySport[key];
        }
        return acc;
      }, {});

      Object.keys(newTournament).forEach((key) => {
        if (!state.availableTournamentsMapBySport[key]) {
          tournamentState[key] = newTournament[key];
        }
      });

      const asd = Object.keys(action.payload.data.tournamentMatchMap).reduce(
        (acc, key) => {
          if (acc[key]) {
            acc[key] = [...acc[key], ...action.payload.data.tournamentMatchMap[key]];
          } else {
            acc[key] = action.payload.data.tournamentMatchMap[key];
          }
          return acc;
        },
        { ...state.tournamentMatchMap }
      );

      const intialGoals = action.payload.data.matches.reduce((acc, match) => {
        const scoreList = match.scores_map.find(([key]) => key === "ft")[1].scoreList;
        acc[match.id] = Number(scoreList[0]) + Number(scoreList[1]);
        return acc;
      });
      return {
        ...state,
        isAuthenticated: true,
        totalItems: action.payload.data.totalItems,
        totalPages: action.payload.data.totalPages,
        currentPage: action.payload.data.currentPage,
        dropDownCount: action.payload.data.dropDownCount,
        matches: matchesState,
        availableSports: { ...state.availableSports, ...action.payload.data.availableSports },
        tournamentMatchMap: asd,
        availableTournamentsMapBySport: tournamentState,
        oddCountsByMatchMap: { ...state.oddCountsByMatchMap, ...action.payload.data.oddCountsByMatchMap },
        availableDetailtOddsMap: { ...state.availableDetailtOddsMap, ...action.payload.data.availableDetailtOddsMap },
        availableOddsMap: sortedAvailableOddsMap,
        odds: { ...state.odds, ...action.payload.data.odds },
        scores: newScores,
        matchesTimer: newMatchesTimers,
        goals: { ...state.goals, ...intialGoals },
        error: null,
      };
    }
    case LIVEMATCHES_REMOVE: {
      const updatedMatches = state.matches.filter((match) => match.id !== action.payload);
      return {
        ...state,
        matches: updatedMatches,
      };
    }
    case LIVEMATCHES_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        error: action.payload,
      };
    case LIVEMATCHES_WEBSOCKET_UPDATE_ODDS_1: {
      if (!action.payload.data || action.payload.data.length === 0) {
        return {
          ...state,
          isAuthenticated: false,
          error: "No odds data found",
        };
      }
      if (!state.matches.some((e) => action.payload.matchId === e.id)) {
        return state;
      }
      const updatedOdds = { ...state.odds };
      action.payload.data.forEach((webOdd) => {
        const id = webOdd.match_id + "_" + webOdd.market_id;
        if (!updatedOdds[id]) {
          return;
        }

        const existingOddIndex = updatedOdds[id].findIndex((odd) => odd.outcome_id === webOdd.outcome_id);
        if (existingOddIndex !== -1) {
          updatedOdds[id][existingOddIndex].value = webOdd.value;
          // updatedOdds[id][existingOddIndex].outcome_id = webOdd.outcome_id;
          // updatedOdds[id][existingOddIndex].line = webOdd.line;
          updatedOdds[id][existingOddIndex].status = webOdd.status;
          updatedOdds[id][existingOddIndex].change = webOdd.change;
          // updatedOdds[id][existingOddIndex].score = webOdd.score;
          // updatedOdds[id][existingOddIndex].line_score = webOdd.line_score;
          // updatedOdds[id][existingOddIndex].main_line = webOdd.main_line;
          // } else {
          // updatedOdds[id].push(webOdd);
        }
      });
      return {
        ...state,
        odds: updatedOdds,
      };
    }
    case "LIVEMATCHES_WEBSOCKET_UPDATE_MATCHES_OLD": {
      if (!action.payload.data) {
        return {
          ...state,
          isAuthenticated: false,
          error: "No matches data found",
        };
      }
      const match_id = action.payload.data.id;
      if (!state.matches.some((e) => e.id === match_id)) {
        return state;
      }

      const updatedMatches = [...state.matches];
      const webMatch = action.payload.data;

      const existingMatchIndex = updatedMatches.findIndex((e) => e.id === webMatch.id);
      let isChanged = false;
      if (existingMatchIndex !== -1) {
        if (!isEqual(updatedMatches[existingMatchIndex].status_id, webMatch.status_id)) {
          updatedMatches[existingMatchIndex].status_id = webMatch.status_id;
          isChanged = true;
        }

        if (!isEqual(updatedMatches[existingMatchIndex].match_time, webMatch.match_time)) {
          updatedMatches[existingMatchIndex].match_time = webMatch.match_time;
          isChanged = true;
        }

        if (!isEqual(updatedMatches[existingMatchIndex].neutral, webMatch.neutral)) {
          updatedMatches[existingMatchIndex].neutral = webMatch.neutral;
          isChanged = true;
        }

        if (!isEqual(updatedMatches[existingMatchIndex].period, webMatch.period)) {
          updatedMatches[existingMatchIndex].period = webMatch.period;
          isChanged = true;
        }

        if (!isEqual(updatedMatches[existingMatchIndex].m_type, webMatch.m_type)) {
          updatedMatches[existingMatchIndex].m_type = webMatch.m_type;
          isChanged = true;
        }

        if (!isEqual(updatedMatches[existingMatchIndex].hide, webMatch.hide)) {
          updatedMatches[existingMatchIndex].hide = webMatch.hide;
          isChanged = true;
        }
      }
      return {
        ...state,
        matches: isChanged ? updatedMatches : state.matches,
      };
    }
    case LIVEMATCHES_WEBSOCKET_UPDATE_MATCHES: {
      if (!action.payload.data) {
        return {
          ...state,
          isAuthenticated: false,
          error: "No matches data found",
        };
      }
      const match_id = action.payload.data.id;
      if (!state.matches.some((e) => e.id === match_id)) {
        return state;
      }

      const updatedMatches = [...state.matches];
      const webMatch = action.payload.data;

      const existingMatchIndex = updatedMatches.findIndex((e) => e.id === webMatch.id);
      let isChanged = false;
      if (existingMatchIndex !== -1) {
        if (!isEqual(updatedMatches[existingMatchIndex].status_id, webMatch.status_id)) {
          updatedMatches[existingMatchIndex].status_id = webMatch.status_id;
          isChanged = true;
        }

        if (!isEqual(updatedMatches[existingMatchIndex].match_time, webMatch.match_time)) {
          updatedMatches[existingMatchIndex].match_time = webMatch.match_time;
          isChanged = true;
        }

        if (!isEqual(updatedMatches[existingMatchIndex].neutral, webMatch.neutral)) {
          updatedMatches[existingMatchIndex].neutral = webMatch.neutral;
          isChanged = true;
        }

        if (!isEqual(updatedMatches[existingMatchIndex].period, webMatch.period)) {
          updatedMatches[existingMatchIndex].period = webMatch.period;
          isChanged = true;
        }

        if (!isEqual(updatedMatches[existingMatchIndex].m_type, webMatch.m_type)) {
          updatedMatches[existingMatchIndex].m_type = webMatch.m_type;
          isChanged = true;
        }

        if (!isEqual(updatedMatches[existingMatchIndex].hide, webMatch.hide)) {
          updatedMatches[existingMatchIndex].hide = webMatch.hide;
          isChanged = true;
        }
      }
      let time = "";
      if (webMatch.timer) {
        if (webMatch.sport_id === 1 && webMatch.period !== 5) {
          const asd = JSON.parse(webMatch.timer);
          const timer = Number(Math.ceil(asd[3] / 60));
          if (timer > 45 && webMatch.period === 35) {
            time = "45′+" + (timer - 45);
          } else if (timer > 90 && webMatch.period === 36) {
            time = "90′+" + (timer - 90);
          } else {
            time = timer + 1 + "′";
          }
        } else {
          time = webMatch.period_name;
        }
      }

      return {
        ...state,
        matches: isChanged ? updatedMatches : state.matches,
        matchesTimer: isEqual(state.matchesTimer[match_id], time)
          ? state.matchesTimer
          : {
              ...state.matchesTimer,
              [match_id]: time,
            },
      };
    }
    case LIVEMATCHES_WEBSOCKET_UPDATE_MATCHES_SCORE:
      if (!state.matches.some((e) => e.id === action.payload.matchId)) {
        return state;
      }
      //SCORE
      const newScores = {
        [action.payload.matchId]: {
          homeTotalScore: Number(action.payload.data.score.resultsList[0]),
          awayTotalScore: Number(action.payload.data.score.resultsList[1]),
          periods: action.payload.data.periodsList.map((e) => ({
            homeScore: Number(e.resultsList[0]),
            awayScore: Number(e.resultsList[1]),
          })),
        },
      };

      //REDCARD

      const [asd] = action.payload.data.statsList.filter((e) => e.type === 2);
      const newRedCards = {
        ...state.redCard,
        [action.payload.matchId]: {
          home: asd?.incidentsList?.filter((e) => e.belong === 1)?.length,
          away: asd?.incidentsList?.filter((e) => e.belong === 2)?.length,
        },
      };

      const [dsa] = action.payload.data.statsList.filter((e) => e.type === 3);
      const newYellowCards = {
        ...state.yellowCard,
        [action.payload.matchId]: {
          home: dsa?.incidentsList?.filter((e) => e.belong === 1)?.length,
          away: dsa?.incidentsList?.filter((e) => e.belong === 2)?.length,
        },
      };
      const totalScore =
        Number(newScores[action.payload.matchId].homeTotalScore) +
        Number(newScores[action.payload.matchId].awayTotalScore);
      return {
        ...state,
        scores: isEqual(state?.scores[action.payload.matchId], newScores[action.payload.matchId])
          ? state.scores
          : {
              ...state.scores,
              [action.payload.matchId]: newScores[action.payload.matchId],
            },
        redCard: isEqual(newRedCards[action.payload.matchId], state?.redCard[action.payload.matchId])
          ? state.redCard
          : {
              ...state.redCard,
              [action.payload.matchId]: newRedCards[action.payload.matchId],
            },
        yellowCard: isEqual(newYellowCards[action.payload.matchId], state?.yellowCard?.[action.payload.matchId])
          ? state?.yellowCard
          : {
              ...state?.yellowCard,
              [action.payload.matchId]: newYellowCards[action.payload.matchId],
            },
        goals:
          totalScore === state?.goals?.[action.payload.matchId]
            ? state.goals
            : {
                ...state.goals,
                [action.payload.matchId]: totalScore,
              },
      };
    case "SELECTED_VALUES": {
      return {
        ...state,
        selectedOdds: isEqual(state.selectedOdds, action.payload) ? state.selectedOdds : action.payload,
      };
    }
    default:
      return state;
  }
};
