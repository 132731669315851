import { Button } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { cashoutUrl, getCouponUrl } from "../../../store/paths";

const TabContent = ({ activeTab }) => {
  const [bets, setBets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchBets();
  }, [activeTab]);

  const fetchBets = async () => {
    try {
      setLoading(true);

      const storedUserData = JSON.parse(localStorage.getItem("authToken"));
      const token = storedUserData?.user?.token;
      if (!token) return;

      const response = await fetch(getCouponUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setError("Error fetching data:" + response.statusText);
        return;
      }

      const data = await response.json();

      const sortedData = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      setBets(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCashout = async (bet_slip_id) => {
    try {
      const storedUserData = JSON.parse(localStorage.getItem("authToken"));
      const token = storedUserData?.user?.token;
      if (!token) return;
      const params = new URLSearchParams({ couponId: bet_slip_id });

      const response = await fetch(cashoutUrl + params.toString(), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        toast.error("Cashout Failed");
        return;
      }
      toast.success("Winnings Processed Successfully");
      setBets((prevBets) => prevBets.map((bet) => (bet.id === bet_slip_id ? { ...bet, coupon_status: "CASHED_OUT" } : bet)));
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setError(error);
    }
  };

  const filterBets = () => {
    let filteredBets = bets;

    if (activeTab === "Open") {
      filteredBets = bets.filter((bet) => bet.coupon_status === "OPEN");
    } else if (activeTab === "Cashout") {
      filteredBets = bets.filter((bet) => bet.coupon_status === "OPEN");
    } else if (activeTab === "Settled") {
      filteredBets = bets.filter((bet) => ["WON", "LOST", "REFUND"].includes(bet.coupon_status));
    } else if (activeTab === "Live") {
      filteredBets = bets
        .map((bet) => ({
          ...bet,
          coupon_lines: bet.coupon_lines.filter((line) => line.is_live === true),
        }))
        .filter((bet) => bet.coupon_lines.length > 0);
    } else if (activeTab !== "All") {
      filteredBets = bets.filter((bet) => bet.coupon_status === activeTab);
    }

    return filteredBets;
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const filteredBets = filterBets();
  return (
    <div>
      {filteredBets.length > 0 ? (
        filteredBets.map((bet) => <BetItem key={bet.id} bet={bet} handleCashout={handleCashout} activeTab={activeTab} />)
      ) : (
        <p>No bets found for this category.</p>
      )}
    </div>
  );
};

const BetItem = ({ bet, handleCashout, activeTab }) => {
  const [isOpen, setIsOpen] = useState(false);
  const formatDate = (date) => {
    const parsedDate = new Date(date);
    const day = String(parsedDate.getDate()).padStart(2, "0");
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const hours = String(parsedDate.getHours()).padStart(2, "0");
    const minutes = String(parsedDate.getMinutes()).padStart(2, "0");
    return `${day}.${month}. ${hours}:${minutes}`;
  };
  const [isConfirming, setIsConfirming] = useState(false);
  const buttonRef = useRef(null);
  const buttonRef2 = useRef(null);
  const handleButtonClick = (e) => {
    console.log("e", e);
    e.stopPropagation();
    if (isConfirming) {
      handleCashout(bet.id);
      setIsConfirming(false);
    } else {
      setIsConfirming(true);
    }
  };

  const handleBlur = () => {
    if (isConfirming === true) {
      setIsConfirming(false);
    }
  };

  return (
    <div className="border rounded-lg shadow-md my-2.5 bg-white" onClick={() => setIsOpen(!isOpen)}>
      {/*Mobile Layout*/}
      <div className="flex flex-col md:hidden p-4 space-y-2 cursor-pointer">
        <div className="flex items-center">
          <div className="flex-grow"></div>

          <span className="text-gray-400">
            <span
              className={`font-semibold mr-2 text-sm ${
                bet.coupon_status === "WON" ? "text-green-500" : bet.coupon_status === "LOST" ? "text-red-500" : "text-yellow-500"
              }`}
            >
              {bet.coupon_status}
            </span>
            {isOpen ? "▲" : "▼"}
          </span>
        </div>
        <span className="absolute text-xs text-black">{bet.system_type}</span>
        <div className="flex items-center ">
          <span className="text-xs text-gray-500">{formatDate(bet.placed_at)}</span>

          {activeTab !== "Settled" && bet.can_cashout && (
            <div className="flex space-x-3 ml-1">
              <div className="border border-[#4d92aa] text-[#4d92aa] h-10 place-content-center px-4 py-1 text-xs rounded font-semibold">
                <span>Auto Cashout</span>
              </div>
              <button
                ref={buttonRef2}
                onClick={handleButtonClick}
                className={`${isConfirming ? "bg-[#22c55e]" : "bg-[#4d92aa]"} text-white  h-10 place-content-center text-xs px-3 py-1 rounded font-semibold`}
                onBlur={handleBlur}
              >
                {isConfirming ? `Confirm cashout for €${parseFloat(bet.cashout_val).toFixed(2)}` : `Cashout for €${parseFloat(bet.cashout_val).toFixed(2)}`}
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Desktop Layout*/}
      <div className="hidden md:flex  items-center h-24 p-4 cursor-pointer">
        <div className="flex items-center space-x-24 flex-grow">
          <span
            className={`font-semibold text-sm w-16 ${
              bet.coupon_status === "WON" ? "text-green-500" : bet.coupon_status === "LOST" ? "text-red-500" : "text-yellow-500"
            }`}
          >
            {bet.coupon_status}
          </span>
          <span className="text-sm text-center w-24">{formatDate(bet.placed_at)}</span>
          <span className="text-sm w-20 text-center">{bet.system_type}</span>
          <span className="text-sm font-semibold text-gray- w-20 text-center">
            <span className="text-xs text-gray-400">Stake</span> <br /> {bet.stake_amount}
          </span>
          <span className="text-sm font-semibold w-20 text-center">
            <span className="text-xs text-gray-400">{bet.can_cashout === true ? "Paid Out" : "Possible gain"}</span>
            <br />
            {bet.possible_win}
          </span>
        </div>
        {activeTab !== "Settled" && bet.can_cashout && (
          <div className="flex space-x-3">
            <div className="border border-[#4d92aa] text-[#4d92aa] h-14 place-content-center px-3 py-1 rounded font-semibold">
              <span>Auto Cashout</span>
            </div>
            <button
              ref={buttonRef}
              onClick={handleButtonClick}
              className={`${isConfirming ? "bg-[#22c55e]" : "bg-[#4d92aa]"} text-white h-14 place-content-center px-3 py-1 rounded font-semibold`}
              onBlur={handleBlur}
            >
              {isConfirming ? `Confirm cashout for €${parseFloat(bet.cashout_val).toFixed(2)}` : `Cashout for €${parseFloat(bet.cashout_val).toFixed(2)}`}
            </button>
          </div>
        )}

        {/* <div></div> */}
        <span className="text-gray-400 ml-2">{isOpen ? "▲" : "▼"}</span>
      </div>

      {isOpen &&
        Array.isArray(bet.coupon_lines) &&
        bet.coupon_lines.map((line, index) => (
          <div key={index} className="p-2 md:pl-56 border-t border-gray-200 bg-customs">
            <div className="flex flex-col text-sm">
              <span className="text-customText ">
                {line.market_name}
                {line.line ? ` (${line.line})` : ""} : <span className="font-bold">{line.outcome_name}</span>
              </span>
              <span>
                {line.match_name}
                <span
                  className={`float-right font-bold ${
                    line.line_status === "WON" ? "text-green-500" : line.line_status === "LOST" ? "text-red-500" : "text-black"
                  }`}
                >
                  {line.odd_value}
                </span>
              </span>
              <span className="text-xs text-gray-500">
                {new Date(line.match_time * 1000).toLocaleString()}{" "}
                {activeTab == "Settled" && (
                  <span>
                    {line.match && line.match.scores_map && (
                      <span className="ml-2 text-gray-400">
                        {line.match.scores_map.map((item, index) => {
                          const [key, value] = item;
                          return (
                            <span key={index} className="ml-1">
                              {key}: [{value.scoreList[0] + "-" + value.scoreList[1]}]
                            </span>
                          );
                        })}
                      </span>
                    )}
                  </span>
                )}
              </span>
            </div>

            {index === bet.coupon_lines.length - 1 && (
              <div className="flex flex-col mt-2">
                <div className="flex justify-between md:hidden">
                  <div className="text-sm font-semibold text-gray-500">
                    <span className="text-xs text-gray-400">Stake</span> <br /> {bet.stake_amount}
                  </div>
                  <div className="text-sm font-semibold text-gray-500">
                    <span className="text-xs text-gray-400">Possible gain</span> <br /> {bet.possible_win}
                  </div>
                </div>
                <div className="border-t  border-gray-200">
                  <p className="text-xs  mt-2 text-gray-500">Betslip ID: {bet.id}</p>
                </div>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

const MyBets = () => {
  const [activeTab, setActiveTab] = useState("Open");

  return (
    <div className="p-6 pt-10 w-full mx-auto">
      <span className="text-xl font-semibold pb-24 hidden sm:block">My Bets</span>

      <div className="flex space-x-1 text-sm mb-4 pt-4 sm:pt-1 border-b border-gray-300">
        {["Open", "Cashout", "Live", "Settled", "All"].map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`pb-2 w-36 ${activeTab === tab ? "text-black font-semibold border-b-2 border-red-500" : "text-gray-500"}`}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      <div className="mt-4">
        <TabContent activeTab={activeTab} />
      </div>
    </div>
  );
};

export default MyBets;
