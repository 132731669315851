import { useDispatch, useSelector } from "react-redux";
import { PopupMatchCombi } from "../../Dialog/PopupMatchCombi";
import { LiveGenericComboboxGroup } from "./LiveGenericComboboxGroup";
import { LiveGenericOddNamesGroup } from "./LiveGenericOddNamesGroup";
import { useEffect, useMemo, useRef, useState } from "react";
import { isEqual } from "lodash";
import { PiSlidersHorizontalLight } from "react-icons/pi";
import CustomSwitch from "../../CustomSwitch";
import { GenericDetailCard } from "../GenericDetailCard";
import { LiveGenericMatchesRow } from "./LiveGenericMatchesRow";
import { initializeWebSocket, LiveMatchesApi } from "../../../store/actions/live_matches";
import { LiveMatchesApi3Hrs } from "../../../store/actions/live_matches_3hrs";
import { sportsApi } from "../../../store/actions/sportsNav";
import { LiveMatchesApi6Hrs } from "../../../store/actions/live_matches_6hrs";
import { showDetailLive } from "../../../store/actions/homeTypes";
import { imagesUrl } from "../../../store/paths";

export const LiveGenericMainPageBody = ({ setHeaderTitle }) => {
  const dispatch = useDispatch();
  const [betTab, setBetTab] = useState();
  const [showSwitch, setShowSwitch] = useState(false);
  const counts = useSelector((state) => state?.apisport?.counts);
  const showDetail = useSelector((state) => state?.homereducer?.showDetailLive);
  useEffect(() => {
    dispatch(
      LiveMatchesApi({
        pageNumber: 1,
        pageSize: 10,
        sport_ids: betTab === 9999 ? undefined : [betTab],
      })
    );
    dispatch(
      LiveMatchesApi3Hrs({
        pageNumber: 1,
        pageSize: 10,
        sport_ids: betTab === 9999 ? undefined : [betTab],
      })
    );
    dispatch(
      LiveMatchesApi6Hrs({
        pageNumber: 1,
        pageSize: 10,
        sport_ids: betTab === 9999 ? undefined : [betTab],
      })
    );
  }, [betTab]);
  const sportsArray = useMemo(() => {
    if (!counts || counts.length === 0) {
      return [];
    }
    const array = [...counts.filter((e) => e.length)];
    const totalLength = array.reduce((sum, sport) => Number(sum) + Number(sport.length), 0);
    array.push({ id: 9999, name: "AllBalls", length: totalLength });
    const minBetTab = Math.min(...array.map((e) => e.id));
    if (betTab || !isEqual(betTab, minBetTab)) {
      setBetTab(minBetTab);
    }
    return array;
  }, [counts]);

  useEffect(() => {
    dispatch(sportsApi());
    const socket = dispatch(initializeWebSocket());
    return () => {
      if (socket && socket.close) {
        socket.close();
      }
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(showDetailLive(null));
  }, [betTab]);

  if (!counts || counts === null || (typeof counts === "object" && Object.keys(counts).length === 0)) {
    return null;
  }

  return (
    <div className="grid grid-cols-12  mb-10">
      <div className={`${showDetail ? "col-span-9" : "col-span-12"} bg-custom `}>
        <div className="flex items-center w-full overflow-y-scroll bg-white md:hidden">
          <div
            className="flex-shrink-0 cursor-pointer bg-white border-r flex flex-col items-center justify-center w-2/12 h-16"
            onClick={() => setShowSwitch(!showSwitch)}
          >
            <PiSlidersHorizontalLight className="text-3xl font-bold" />
          </div>
          {showSwitch && (
            <div>
              <CustomSwitch />
            </div>
          )}
          {sportsArray.map((entries) => {
            return (
              <div
                key={entries.id}
                onClick={() => {
                  setBetTab(entries.id);
                  setHeaderTitle(entries.name);
                }}
                className={`flex-shrink-0 cursor-pointer flex flex-col py-2 bg-white justify-between items-center w-2/12 h-16 ${
                  betTab === entries.id ? "border-b-2 border-b-red-700" : "bg-livePageTop"
                }`}
              >
                <div className="flex  gap-1">
                  <img
                    style={{ textAlign: "center", marginLeft: "14px" }}
                    src={imagesUrl + entries.name.toLowerCase().replace(" ", "-") + ".png"}
                    width={30}
                    height={30}
                  />
                  <span style={{ fontSize: "11px" }} className="text-xs text-gray-500 font-extralight">
                    {entries.length}
                  </span>
                </div>
                <span style={{ fontSize: "11px" }} className="flex w-full text-xs text-gray-500 font-semibold justify-center">
                  {entries.name}
                </span>
              </div>
            );
          })}
        </div>

        <div className="flex items-center w-full overflow-x-auto gap-1.5 bg-custom md:mt-12 md:flex hidden">
          {sportsArray.map((entries) => {
            return (
              <div
                key={entries.id}
                onClick={() => {
                  setBetTab(entries.id);
                }}
                className={`flex-shrink-0 cursor-pointer flex flex-col py-2 justify-between grid-cols-1 my-1 rounded-md items-end w-16 h-16 ${
                  betTab === entries.id ? "bg-white border-b-2 border-b-red-700" : "bg-livePageTop shadow-sm shadow-gray-400"
                }`}
              >
                <div className="flex justify-end gap-1 px-2">
                  <img style={{ textAlign: "center" }} src={imagesUrl + entries.name.toLowerCase().replace(" ", "-") + ".png"} width={25} height={25} />
                  <span style={{ fontSize: "11px" }} className="text-xs text-gray-500 font-extralight">
                    {entries.length}
                  </span>
                </div>
                <span style={{ fontSize: "11px" }} className="flex w-full text-xs text-gray-500 font-semibold justify-center">
                  {entries.name}
                </span>
              </div>
            );
          })}
        </div>

        <div className={`flex items-center text-xs w-full gap-4 hidden md:flex  py-4`}>
          <span>Sort by:</span>
          <CustomSwitch />
        </div>
        <div className="grid grid-cols-12">
          <SelectOddNameMatches betTab={betTab} type={"livematches"} isLive={true} showDetail={showDetail} />
          <SelectOddNameMatches className={"pt-10"} betTab={betTab} type={"livematches3Hrs"} isLive={false} showDetail={showDetail} title={"3 Hours"} />
          <SelectOddNameMatches className={"pt-10"} betTab={betTab} type={"livematches6Hrs"} isLive={false} showDetail={showDetail} title={"6 Hours"} />
        </div>
      </div>
      {showDetail ? (
        <>
          <div className="fixed inset-0  flex justify-center items-center bg-custom overflow-auto scrollbar-hide md:hidden">
            <div className="w-full h-full">
              <GenericDetailCard type="showDetailLive" />
            </div>
          </div>
          <div className={`col-span-3 flex justify-center bg-custom overflow-auto scrollbar-hide  `}>
            <div
              className={`fixed mt-11 h-[100vh] z-[-1] md:z-[50] col-md-5 transition-transform duration-1000 ease-in-out transform ${
                showDetail ? "translate-x-1" : "translate-x-12"
              }`}
            >
              {showDetail && <GenericDetailCard type="showDetailLive" />}
            </div>
          </div>
        </>
      ) : null}
      <PopupMatchCombi />
    </div>
  );
};

const SelectOddNameMatches = ({ className, type, showDetail, betTab, isLive, title }) => {
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => Number(state?.[type]?.currentPage));
  const totalPages = useSelector((state) => Number(state?.[type]?.totalPages));
  const buttonRef = useRef(null);

  const handleNextPage = () => {
    if (type === "livematches") {
      dispatch(
        LiveMatchesApi({
          pageNumber: currentPage + 1,
          pageSize: 10,
          sport_ids: betTab === 9999 ? undefined : [betTab],
        })
      );
    } else if (type === "livematches3Hrs") {
      dispatch(
        LiveMatchesApi3Hrs({
          pageNumber: currentPage + 1,
          pageSize: 10,
          sport_ids: betTab === 9999 ? undefined : [betTab],
        })
      );
    } else if (type === "livematches6Hrs") {
      dispatch(
        LiveMatchesApi6Hrs({
          pageNumber: currentPage + 1,
          pageSize: 10,
          sport_ids: betTab === 9999 ? undefined : [betTab],
        })
      );
    }
  };
  const matches = useSelector((state) => state?.[type]?.matches);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          handleNextPage();
        }
      },
      { threshold: 0.5 }
    );

    if (buttonRef.current) {
      observer.observe(buttonRef.current);
    }

    return () => {
      if (buttonRef.current) {
        observer.unobserve(buttonRef.current);
      }
    };
  }, [handleNextPage]);

  if (!matches || matches.length === 0) {
    return null;
  }

  return (
    <div className={`${className} col-span-12 grid`}>
      <div className={`col-md-7 transition-transform duration-1000 w-full ease-in-out transform ${showDetail ? "-translate-x-0" : "translate-x-0"}`}>
        {title && <div className="pl-2 font-bold h-14 place-content-center bg-white">Next {title}</div>}
        <div className="shadow-xl">
          <OddNameMatches type={type} betTab={betTab} isLive={isLive} showDetail={showDetail} matches={matches} />
        </div>
      </div>
      {totalPages !== currentPage && <div ref={buttonRef} onClick={handleNextPage} className="w-full h-10 bg-transparent" />}
    </div>
  );
};

const OddNameMatches = ({ type, betTab, isLive, showDetail, matches }) => {
  const [selectedOddsIds, setSelectedOddsIds] = useState({});

  const handleComboboxValuesChange = (values, param) => {
    const filteredValues = values.filter((value) => value !== null && value !== "");
    setSelectedOddsIds((prevSelectedOddsIds) => ({
      ...prevSelectedOddsIds,
      [param]: filteredValues,
    }));
  };

  const tournamentMatchMap = useSelector((state) => state?.[type]?.tournamentMatchMap);
  const availableSports = useSelector((state) => state?.[type]?.availableSports);
  const availableTournamentsMapBySport = useSelector((state) => state?.[type]?.availableTournamentsMapBySport);

  const selectionMode = useSelector((state) => state?.homereducer?.customSwitch);

  const filterMatches = (tournamentId) => {
    if (selectionMode) {
      const filteredMatches = matches
        ?.filter((match) => Number(match.sport_id) === Number(tournamentId))
        .sort((a, b) => {
          if (a.timer && b.timer) {
            const timerA = JSON.parse(a.timer)[3];
            const timerB = JSON.parse(b.timer)[3];

            if (timerA === timerB) {
              return b.match_time - a.match_time;
            } else {
              return timerB - timerA;
            }
          } else {
            return b.match_time - a.match_time;
          }
        });
      return filteredMatches || [];
    } else {
      const tournamentMatches = tournamentMatchMap[tournamentId];
      if (!tournamentMatches) {
        console.log(`No matches found for tournamentId: ${tournamentId}`);
        return [];
      }

      const filteredMatches = matches?.filter((match) => tournamentMatches.includes(match.id));
      return filteredMatches || [];
    }
  };

  if (!matches) {
    return <div>No matches found.</div>;
  }

  if ((!betTab || !availableTournamentsMapBySport?.[betTab]) && betTab !== 9999) {
    return null;
  }
  if (selectionMode) {
    return Object.entries(availableSports).map(([key, x]) => (
      <div className="overflow-visible scrollbar-hide relative" key={key}>
        <div className="flex h-16 md: gap-4 w-full sticky top-11 z-20 md:pb-4 md:pt-4 bg-customs">
          {betTab === 9999 && (
            <div className="md:hidden flex items-center">
              <img src={imagesUrl + x.name.toLowerCase().replace(" ", "-") + ".png"} className="ml-3" width={30} height={40} />
            </div>
          )}
          <LiveGenericComboboxGroup
            type={type}
            onValuesChange={handleComboboxValuesChange}
            showDetail={showDetail}
            sportName={x.name}
            betTab={betTab === 9999 ? key : betTab}
          />
        </div>

        <div className="bg-white w-full">
          <div className="grid grid-cols-12">
            <div className="col-span-12">
              <Matches
                type={type}
                name={x.name}
                matches={filterMatches(key)}
                selectedOddsIds={betTab === 9999 ? selectedOddsIds[key] || [] : selectedOddsIds[betTab] || []}
                isLive={isLive}
              />
            </div>
          </div>
        </div>
      </div>
    ));
  } else {
    return Object.entries(availableTournamentsMapBySport).map(([key, x]) => (
      <div className="overflow-visible scrollbar-hide relative" key={key}>
        <div className="flex md:gap-4 w-full sticky top-10 z-20 md:pb-4 md:pt-4 bg-customs">
          {betTab === 9999 && (
            <div className="md:hidden flex items-center">
              <img src={imagesUrl + x?.[0]?.sport_name.toLowerCase().replace(" ", "-") + ".png"} className="ml-3" width={30} height={40} />
            </div>
          )}
          <LiveGenericComboboxGroup
            type={type}
            onValuesChange={handleComboboxValuesChange}
            showDetail={showDetail}
            sportName={x?.[0]?.sport_name}
            betTab={betTab === 9999 ? x?.[0]?.sport_id : betTab}
          />
        </div>

        <div className="bg-white w-full">
          <div className="grid grid-cols-12">
            <div className="col-span-12">
              {x.map((item) => (
                <Matches
                  key={item.id}
                  type={type}
                  name={item.name}
                  country_id={item.country_id}
                  matches={filterMatches(item.id)}
                  selectedOddsIds={betTab === 9999 ? selectedOddsIds[x?.[0]?.sport_id] || [] : selectedOddsIds[betTab] || []}
                  isLive={isLive}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    ));
  }
};

const Matches = ({ type, selectedOddsIds, name, country_id, matches, isLive }) => {
  const selectionMode = useSelector((state) => state?.homereducer?.customSwitch);

  return (
    <div className={`grid-rows-${matches.length} col-span-12 md:overflow-visible md:scrollbar-hide`}>
      {matches.length > 0 && (
        <div className="hidden md:block md:sticky md:top-9 md:z-10">
          <LiveGenericOddNamesGroup
            className={`px-6 pl-4`}
            type={type}
            selectedOddsIds={selectedOddsIds}
            tournamentName={selectionMode ? null : `${country_id} / ${name}`}
          />
        </div>
      )}

      {matches.map((match) => (
        <div key={match.id} className="row-span-1 grid grid-rows-1 col-span-12 h-24 md:h-14">
          <LiveGenericMatchesRow
            key={match.id}
            home_team_name={match.home_team_name}
            home_team_logo={match.home_team_logo}
            away_team_name={match.away_team_name}
            away_team_logo={match.away_team_logo}
            isLive={isLive}
            matchId={match.id}
            periodId={match.period}
            selectedOddsIds={selectedOddsIds}
            sportId={match.sport_id}
            status={match.status_id}
            type={type}
            matchTime={match.match_time}
          />
        </div>
      ))}
    </div>
  );
};
