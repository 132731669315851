import axios from "axios";
import {
  LIVEMATCHES_SUCCESS,
  LIVEMATCHES_FAILURE,
  LIVEMATCHES_WEBSOCKET_UPDATE_ODDS_1,
  LIVEMATCHES_WEBSOCKET_UPDATE_MATCHES,
  LIVEMATCHES_WEBSOCKET_UPDATE_MATCHES_SCORE,
  LIVEMATCHES_REMOVE,
} from "./actiontypes";
import { lives_matches, webSocketUrl } from "../paths";
import { io } from "socket.io-client";
import { sportsApi } from "./sportsNav";

export const LiveMatchesApi = (body) => async (dispatch) => {
  try {
    const response = await axios.post(lives_matches, body);
    if (body.pageNumber === 1) {
      dispatch({
        type: LIVEMATCHES_SUCCESS,
        payload: {
          data: response.data,
        },
      });
    } else {
      dispatch({
        type: "LIVEMATCHES_SUCCESS_ADD",
        payload: {
          data: response.data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: LIVEMATCHES_FAILURE,
      payload: error.response?.data?.message || "api failed. Please try again.",
    });
  }
};

export const removeMatches = (match_id) => async (dispatch) => {
  dispatch({
    type: LIVEMATCHES_REMOVE,
    payload: match_id,
  });
  dispatch(sportsApi());
};

export const initializeWebSocket = () => (dispatch) => {
  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjAsInVzZXJuYW1lIjoicGFraXN0YW5sYWhvcmUiLCJhY3RpdmUiOnRydWUsImxvZ2luX2RhdGUiOiIyMDI0LTA5LTE1VDE3OjM2OjA0LjcwM1oiLCJwYXJlbnRfaWQiOm51bGwsInJvbGVfaWQiOjQsImJhbGFuY2UiOiIwLjAwMCIsImNob2ljZXMiOm51bGwsImNyZWF0ZWRBdCI6IjIwMjQtMDktMTVUMTc6MzY6MDQuNzAzWiIsInVwZGF0ZWRBdCI6IjIwMjQtMDktMTVUMTc6MzY6MDQuNzAzWiIsInJvbGUiOnsiaWQiOjQsIm5hbWUiOiJVU0VSIiwicG93ZXIiOjEsImNyZWF0ZWRBdCI6IjIwMjQtMDgtMDJUMjE6MDA6MDAuMDAwWiIsInVwZGF0ZWRBdCI6IjIwMjQtMDgtMDJUMjE6MDA6MDAuMDAwWiJ9LCJpYXQiOjE3MjczNzAzNjcsImV4cCI6MTcyNzM3Mzk2N30.v_7uLLdWTggkMVzECQ16va3iT9wR5E9LaARuuTjpnRk";

  const socket = io(webSocketUrl, {
    reconnectionDelayMax: 10000,
    auth: {
      token: token,
    },
    pingInterval: 15000, // 15 saniye
    pingTimeout: 20000, // 20 saniye

    transports: ["websocket"],
  });

  socket.on("odds_1", (data) => {
    dispatch({
      type: LIVEMATCHES_WEBSOCKET_UPDATE_ODDS_1,
      payload: data,
    });
  });

  socket.on("match", (data) => {
    dispatch({
      type: LIVEMATCHES_WEBSOCKET_UPDATE_MATCHES,
      payload: data,
    });
  });

  socket.on("match_score", (data) => {
    dispatch({
      type: LIVEMATCHES_WEBSOCKET_UPDATE_MATCHES_SCORE,
      payload: data,
    });
  });

  socket.on("connect", () => {
    console.log("Connected to Socket.IO server");
  });

  socket.on("connect_error", (err) => {
    console.error("Connection error:", err);
  });

  socket.on("disconnect", () => {
    console.log("Socket disconnected");
  });

  return socket;
};

export const setSelectedComboValues = (selectedValues) => (dispatch) => {
  dispatch({
    type: "SELECTED_VALUES",
    payload: selectedValues,
  });
};
