import React from "react";
import registerImage from "../../../assets/images/register.jpeg";
import registerImageMobil from "../../../assets/images/register-mobil.avif";
import RegistrationForm  from "../../../components/AuthComponents/RegisterForm";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedItem } from "../../../store/actions/sidebarActions";
import { leftarrow } from "../../../assets/images";
const RegisterPage = () => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="w-full flex justify-center bg-gray-100">
        <div className="flex flex-col items-center justify-center max-w-[1000px] h-full">
          <div className="w-full flex flex-col md:flex-row h-full bg-white md:mt-12">
            {/* Image Section - 50% width */}
            <div className="w-1/2  hidden sm:block">
              <img
                src={registerImage}
                alt="Register"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="md:hidden">
              <div className="relative">
                <Link className="active inline absolute top-3 left-3" to="/" onClick={() => dispatch(setSelectedItem(undefined))}>
                  <img src={leftarrow} alt="" />
                </Link>

                <div className="absolute text-white bottom-6 left-6">
                  <p>New customer bonus!</p>
                  <p>We double your first deposit</p>
                  <p className="font-bold text-3xl">Up to 100€</p>
                </div>
                <img
                    src={registerImageMobil}
                    alt="Register"
                    className="w-full object-cover"
                  />
              </div>
            </div>

            {/* Form Section - 50% width */}
            <div className="w-full md:w-1/2 flex justify-center items-center">
              
               <RegistrationForm/>
          
            </div>
          </div>
          <div className="text-sm text-center my-6">
            *With your registration for our offer you confirm that you have a
            betting account with the Tipico Co. Ltd. would like to open its own
            name and invoice. In addition, you confirm that you are at least 18
            years old and not excluded from the offer for gambling addiction
            (prevention). The registration of a Tipico account allows the use of
            various services offered by Tipico or Tipico group companies and is
            subject to the
            <span className="text-[#4D90A7] font-extralight">
              {" "}
              General Terms and Conditions (GTC){" "}
            </span>{" "}
            and, if applicable, additional terms and conditions. Any personal
            data provided by you will be processed in accordance with our
            <span className="text-[#4D90A7] font-extralight">
              {" "}
              privacy policy
            </span>
            .
          </div>
        </div>
      </div>
      
    </>
  );
};

export default RegisterPage;
