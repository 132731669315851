import { sortAvailableOdds } from "../../components/utility/AvailableOddsMapSorting";
import { TOPMATCHES_FAILURE, TOPMATCHES_SUCCESS, TOPMATCHES_WEBSOCKET_UPDATE_ODDS_2 } from "../actions/actiontypes";

const initialState = {
  isAuthenticated: false,
  totalItems: 0,
  totalPages: 0,
  currentPage: 1,
  matches: [],
  availableSports: null,
  tournamentMatchMap: null,
  availableTournamentsMapBySport: null,
  oddCountsByMatchMap: null,
  dropDownCount: 5,
  availableDetailtOddsMap: null,
  availableOddsMap: null,
  odds: null,
  error: null,
};

export const TopMatchReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOPMATCHES_SUCCESS: {
      const sortedAvailableOddsMap = sortAvailableOdds(action.payload.data.availableOddsMap);
      return {
        ...state,
        isAuthenticated: true,
        totalItems: action.payload.data.totalItems,
        totalPages: action.payload.data.totalPages,
        currentPage: action.payload.data.currentPage,
        dropDownCount: action.payload.data.dropDownCount,
        matches: action.payload.data.matches,
        availableSports: action.payload.data.availableSports,
        tournamentMatchMap: action.payload.data.tournamentMatchMap,
        availableTournamentsMapBySport: action.payload.data.availableTournamentsMapBySport,
        oddCountsByMatchMap: action.payload.data.oddCountsByMatchMap,
        availableDetailtOddsMap: action.payload.data.availableDetailtOddsMap,
        availableOddsMap: sortedAvailableOddsMap,
        odds: action.payload.data.odds,
        error: null,
      };
    }
    case TOPMATCHES_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        error: action.payload,
      };
    case TOPMATCHES_WEBSOCKET_UPDATE_ODDS_2: {
      if (!action.payload.data || action.payload.data.length === 0) {
        return {
          ...state,
          isAuthenticated: false,
          error: "No odds data found",
        };
      }
      if (!state.matches.some((e) => action.payload.matchId === e.id)) {
        return state;
      }
      const updatedOdds = { ...state.odds };
      action.payload.data.forEach((webOdd) => {
        const id = webOdd.match_id + "_" + webOdd.market_id;
        if (!updatedOdds[id]) {
          return;
        }

        const existingOddIndex = updatedOdds[id].findIndex((odd) => odd.name === webOdd.name);
        if (existingOddIndex !== -1 && updatedOdds[id][existingOddIndex].value !== webOdd.value) {
          updatedOdds[id][existingOddIndex].value = webOdd.value;
          updatedOdds[id][existingOddIndex].outcome_id = webOdd.outcome_id;
          updatedOdds[id][existingOddIndex].line = webOdd.line;
          updatedOdds[id][existingOddIndex].status = webOdd.status;
          updatedOdds[id][existingOddIndex].change = webOdd.change;
          updatedOdds[id][existingOddIndex].score = webOdd.score;
          updatedOdds[id][existingOddIndex].line_score = webOdd.line_score;
          updatedOdds[id][existingOddIndex].main_line = webOdd.main_line;
        } else {
          // updatedOdds[id].push(webOdd);
        }
      });
      return {
        ...state,
        odds: updatedOdds,
      };
    }
    default:
      return state;
  }
};
