import axios from "axios";
import { top_matches } from "../paths";

export const LiveMatchesApi6Hrs = (body) => async (dispatch) => {
  try {
    body.hours = 6;
    const response = await axios.post(top_matches, body);
    if (body.pageNumber === 1) {
      dispatch({
        type: "LIVEMATCHES_SUCCESS_6HRS",
        payload: {
          data: response.data,
        },
      });
    } else {
      dispatch({
        type: "LIVEMATCHES_SUCCESS_ADD_6HRS",
        payload: {
          data: response.data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: "LIVEMATCHES_FAILURE_6HRS",
      payload: error.response?.data?.message || "api failed. Please try again.",
    });
  }
};
