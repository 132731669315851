import { memo } from "react";
import { useSelector } from "react-redux";

export const GenericDetails = memo(({ className, type, matchId, handleDetail }) => {
  const oddCount = useSelector((state) => state?.[type]?.oddCountsByMatchMap?.[matchId]);
  return (
    <div className={`${className}`} onClick={handleDetail}>
      <div className="flex hidden md:block justify-end">
        <DetailSvg />
      </div>
      <Count oddCount={oddCount} />
    </div>
  );
});

const Count = ({ oddCount }) => (
  <div className="flex justify-start w-6">
    <span className="text-xs">{oddCount}</span>
    <ArrowSvg />
  </div>
);

const DetailSvg = () => (
  <span>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 28 28" fill="none">
      <path
        d="M11.5 20V13H7V20H11.5ZM11.5 20H16M11.5 20V4H16V20M16 20H20.5V10H16V20Z"
        stroke="#212121"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

const ArrowSvg = () => (
  <span>
    <svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 -960 960 960" width="14px" fill="#CCCCCC">
      <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
    </svg>
  </span>
);
