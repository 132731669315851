import { memo } from "react";
import { useSelector } from "react-redux";

export const GenericScores = memo(({ matchId, sportId }) => {
  const score = useSelector((state) => state?.livematches?.scores?.[matchId]);
  if (!score) {
    return null;
  }
  if (sportId === 1) {
    return <AllPeriods score={score} />;
  } else if (sportId === 2 || sportId === 8) {
    return <TotalScore score={score} />;
  } else if (sportId === 3 || sportId === 10) {
    return <TotalScoreSetScore score={score} />;
  } else {
    return <TotalScore score={score} />;
  }
});

const AllPeriods = ({ score }) => {
  const periodScores = score?.periods.slice(0, -1);
  return (
    <div className="flex gap-2 justify-end mr-1 md:mr-6">
      {periodScores?.map((pScore, index) => (
        <div key={index} className="flex flex-col gap-2 justify-end">
          <span className={`text-sm font-semibold text-combisColor text-right`}>{pScore.homeScore}</span>
          <span className={`text-sm font-semibold text-combisColor text-right`}>{pScore.awayScore}</span>
        </div>
      ))}
      <div className="flex flex-col gap-2 justify-end">
        <span className={`text-sm font-semibold text-combisColor text-right animate-pulse`}>
          {score.homeTotalScore}
        </span>
        <span className={`text-sm font-semibold text-combisColor text-right animate-pulse`}>
          {score.awayTotalScore}
        </span>
      </div>
    </div>
  );
};

const TotalScore = ({ score }) => (
  <div className="flex gap-2 justify-end mr-1 md:mr-6">
    <div className="flex flex-col gap-2 justify-end">
      <span className={`text-sm font-semibold text-combisColor text-right animate-pulse`}>{score.homeTotalScore}</span>
      <span className={`text-sm font-semibold text-combisColor text-right animate-pulse`}>{score.awayTotalScore}</span>
    </div>
  </div>
);

const TotalScoreSetScore = ({ score }) => (
  <div className="flex gap-2 justify-end mr-1 md:mr-6 items-center">
    <div className="flex flex-col gap-2 justify-end">
      <span className={`text-sm font-semibold text-combisColor text-right`}>{score.homeTotalScore}</span>
      <span className={`text-sm font-semibold text-combisColor text-right`}>{score.awayTotalScore}</span>
    </div>
    <div className="w-px h-3/4 bg-gray-300 content-center"></div>
    <div className="flex flex-col gap-2 justify-end">
      <span className={`text-sm font-semibold text-combisColor text-right animate-pulse`}>0</span>
      <span className={`text-sm font-semibold text-combisColor text-right animate-pulse`}>0</span>
    </div>
  </div>
);
