import React, { useMemo, useEffect, useState, act, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showDetailHome, showDetailLive } from "../../store/actions/homeTypes";
import {
  detail1,
  detail2,
  detail3,
  detail4,
  detail5,
  detail6,
  detail7,
  detail8,
  shirt1,
  shirt2,
} from "../../assets/images/index";
import "../../app/pages/user-page/userPage.css";
import moment from "moment";
import { changeBetBuilder, showPopup } from "../../store/actions/calculateBetSlipAction";
import { FaRegEye, FaRegEyeSlash, FaRegUserCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../store/actions/authActions";
import { useHandleOddsClick } from "../utility/handleOdd";
import { isEqual } from "lodash";
import { sortingOddsMap } from "../utility/AvailableOddsMapSorting";

export const GenericDetailCard = ({ type = "showDetail" }) => {
  const dispatch = useDispatch();

  const test = useSelector((state) => state?.homereducer?.[type]);
  const matches = useSelector((state) => state?.[test?.type]?.matches);
  const match = matches?.filter((e) => e.id === test?.matchId)?.[0];
  const odds = useSelector((state) => state?.[test?.type]?.odds);
  const isLive = test?.isLive;

  const filteredData = useMemo(() => {
    const values = Object.keys(odds ?? []).reduce((acc, key) => {
      if (key.startsWith(match?.id)) {
        acc.push(odds[key]);
      }
      return acc;
    }, []);
    return values;
  }, [match?.id, odds]);

  const sortedFilteredData = filteredData.sort((a, b) => {
    return a[0].market_id - b[0].market_id;
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isBalanceVisible, setIsBalanceVisible] = useState(true);
  const navigate = useNavigate();
  const storedUserData = JSON.parse(localStorage.getItem("authToken"));
  const userName = storedUserData?.user?.user?.name || "User Name";

  const [balance, setBalance] = useState(() => {
    const savedBalance = localStorage.getItem("balance");
    return savedBalance ? JSON.parse(savedBalance) : 0;
  });

  useEffect(() => {
    if (userName) {
      const timeoutId = setInterval(() => {
        const savedBalance = localStorage.getItem("balance");
        if (savedBalance && !isEqual(savedBalance, balance)) {
          setBalance(savedBalance);
        }
      }, 2000);
      return () => clearInterval(timeoutId);
    }
  }, [userName]);

  const useAuth = () => {
    const authToken = localStorage.getItem("authToken");
    return authToken ? JSON.parse(authToken) : { isAuthenticated: false };
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const { isAuthenticated } = useAuth();
  const ids = storedUserData?.user?.user?.id;
  const oddList = useSelector((state) => state?.homereducer?.odds);
  const betBuilderMatches = useSelector((state) => state?.calculateBetSlip?.betBuilderMatches);

  const alleCombiChange = (active, matchId, name) => {
    if (oddList && oddList.length > 0) {
      dispatch(showPopup(active, matchId));
    } else {
      dispatch(changeBetBuilder(matchId, name, active));
    }
  };

  const isMatchCombi = betBuilderMatches.some((e) => e.matchId === match?.id && e.active);
  return (
    <div className=" pt-1 relative bg-oddColor md:rounded-md md:mr-3 w-screen md:pr-0 md:w-[400px]  text-white">
      <div className="bg-oddColor text-white">
        <div className="flex justify-between items-center mb-4 p-1 w-full border-b border-gray-600 md:border-b md:border-oddColor">
          <button
            onClick={() => {
              dispatch(showDetailLive(null));
              dispatch(showDetailHome(null));
            }}
            className="text-white md:hidden"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
            </svg>
          </button>

          {/* Başlık */}
          <div className="text-center flex-1 ml-[10%] md:ml-4">
            <div className="font-bold text-sm">{match?.tournamentName}</div>
          </div>

          <div className="flex justify-between md:block hidden ">
            <div className="grid grid-cols-1 text-center"></div>
            <div
              onClick={() => {
                dispatch(showDetailLive(null));
                dispatch(showDetailHome(null));
              }}
              className="cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#e8eaed"
              >
                <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
              </svg>
            </div>
          </div>

          {/* Login veya Kullanıcı Bilgisi */}
          <div className="text-right md:hidden">
            {isAuthenticated ? (
              <div className="flex items-center space-x-4">
                <div className="relative">
                  <div
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    className="flex flex-col items-center cursor-pointer"
                  >
                    <FaRegUserCircle size={20} className="text-white" />
                    <div className="text-xs text-white mt-1">{balance} €</div>
                  </div>

                  {isDropdownOpen && (
                    <div className="absolute top-full right-0 mt-2 w-64 bg-white rounded-md shadow-lg z-50 p-4">
                      <div className="text-center font-semibold text-gray-800">{ids}</div>
                      <div className="flex items-center border-t border-gray-300 justify-between mt-4">
                        <div className="mt-3 flex">
                          <span className="text-gray-600 mr-7">Kontoguthaben</span>
                          <div className="flex items-center">
                            <button
                              onClick={() => setIsBalanceVisible(!isBalanceVisible)}
                              className="focus:outline-none"
                            >
                              {isBalanceVisible ? <FaRegEye /> : <FaRegEyeSlash />}
                            </button>
                            <span className="ml-2 font-semibold text-gray-800">
                              {isBalanceVisible ? balance : "****"}
                            </span>
                          </div>
                        </div>
                      </div>

                      <button className="w-full bg-green-600 hover:bg-green-700 text-white font-semibold py-2 rounded mt-4">
                        Einzahlung
                      </button>

                      <div className="mt-4 border-t pt-2 text-left">
                        <Link to="/userPage" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                          Mein Konto
                        </Link>
                        <Link to="/news" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                          Neuigkeiten
                        </Link>
                        <Link to="/payout" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                          Auszahlung
                        </Link>
                        <Link to="/help" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                          Hilfe
                        </Link>
                        <Link to="/settings" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                          Einstellungen
                        </Link>

                        <button onClick={handleLogout} className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                          Logout
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <Link to="/login">
                <button className="text-xs w-14 h-8 border-2 p-1 rounded-md border-white text-white hover:bg-transparent">
                  Login
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>

      {/* Alt Kısım: Sol, Orta, Sağ Bilgi */}
      <Header
        isLive={isLive}
        home_team_name={match?.home_team_name}
        home_team_logo={match?.home_team_logo}
        away_team_name={match?.away_team_name}
        away_team_logo={match?.away_team_logo}
        match_time={match?.match_time}
        sportId={match?.sport_id}
        matchId={match?.id}
      />

      <div className="w-full h-[90vh] ">
        {/* Main Section */}
        <div className="w-full md:px-3 md:pt-2 h-[79vh] pb-4 overflow-auto">
          <div className="bg-custom">
            <IFrame tid={match?.live_tracker_params} sportName={match?.sportName} />
            <div className="flex justify-center p-1 pb-5  mt-3 w-78 space-x-2">
              <button
                className={`px-1 pl-2 py-3 h-9 border-2 flex items-center justify-evenly flex-1 rounded-3xl text-customDetail font-semibold text-black ${
                  !isMatchCombi ? "bg-buttonbgdetail text-white" : "bg-white"
                }`}
                onClick={() => {
                  alleCombiChange(false, match.id, match.home_team_name + "-" + match.away_team_name);
                }}
              >
                Alle Wetten
              </button>

              <button
                className={`px-1 py-3 h-9 flex border-2 items-center  ${
                  isMatchCombi ? "bg-buttonbgdetail text-white" : " bg-white text-black"
                }  rounded-3xl text-customDetail font-semibold `}
                onClick={() => {
                  alleCombiChange(true, match.id, match.home_team_name + "-" + match.away_team_name);
                }}
              >
                <span className="mr-1">
                  <img src={detail7} />
                </span>
                MatchKombi+
                <span className="mr-2 px-1 bg-red-700 text-white font-semibold text-xxs p-1 rounded-xl ">Neu</span>
              </button>
              <button className="px-1 py-3 h-9 flex border-2 items-center bg-white rounded-3xl text-customDetail font-semibold text-black hover:bg-gray-100">
                <span className="mr-1">
                  <img src={detail8} />
                </span>
                Top-MatchKombis
              </button>
            </div>
          </div>

          <div className="flex flex-wrap w-full justify-between pt-2 shadow-md px-3 bg-white text-gray-500">
            {sortedFilteredData.map((e) => {
              const groupedData = e.reduce((acc, k) => {
                if (!acc[k.line]) {
                  acc[k.line] = [];
                }
                acc[k.line].push({
                  value: k.value,
                  name: k.name,
                  id: k.id,
                  line: k.line,
                  market_name: k.market_name,
                  outcome_id: k.outcome_id,
                  market_id: k.market_id,
                  status: k.status,
                });
                return acc;
              }, {});
              const sortedGroupedData = sortingOddsMap(groupedData);
              return (
                <div key={e.id} className="w-full">
                  <div className="font-bold text-left text-detailText text-sm mt-5 mb-3">{e[0].market_name}</div>
                  <div className="flex flex-wrap gap-2">
                    {Object.keys(sortedGroupedData).map((line, index) => (
                      <div key={index} className="flex w-full mb-3">
                        {line ? <span className="text-xs text-detailText text-left w-1/4">{line}</span> : null}
                        <div className={`flex flex-wrap ${line ? "w-3/4" : "w-full"} gap-2 justify-end`}>
                          {sortedGroupedData[line].map((value) => {
                            return (
                              <OddButton
                                key={match?.id + "-" + value.market_id + "-" + value.outcome_id}
                                match={match}
                                odd={value}
                              />
                            );
                          })}
                        </div>
                      </div>
                    ))}
                  </div>
                  <hr className="border-1 border-gray-200 " />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const OddButton = memo(({ match, odd }) => {
  const [isSelected, setIsSelected] = useState(false);
  const errors = useSelector((state) => state?.calculateBetSlip?.errors);
  const oddsList = useSelector((state) => state.homereducer.odds);
  const isHaveError =
    errors.includes(match.id) && oddsList.some((e) => e.id == match.id + "-" + odd.market_id + "-" + odd.outcome_id);

  const handleOdd = useHandleOddsClick();
  const nonCombineMarkets = useSelector((state) => state?.calculateBetSlip?.nonCombineMarkets);

  const isAvailable = nonCombineMarkets.includes(match.id + "-" + odd.market_id + "-" + odd.outcome_id);

  const bets = useSelector((state) => state?.homereducer.odds);

  useEffect(() => {
    const bet = bets.filter((e) => e.id === match.id + "-" + odd.market_id + "-" + odd.outcome_id);
    setIsSelected(bet !== null && bet.length !== 0);
  }, [bets]);

  if (odd.status !== 0) {
    return (
      <div className="flex justify-center h-[32px] w-[75px] md:h-8 bg-newButton md:bg-buttonbg rounded-custom items-center border md:shadow-md border-gray-300 py-2 cursor-not-allowed col-span-1">
        <span className="text-gray-700">
          <svg xmlns="http://www.w3.org/2000/svg" width="11" height="15" viewBox="0 0 11 15">
            <g fill="none" fillRule="evenodd" transform="translate(0 1)">
              <rect width="11" height="9" y="5" fill="#BBB" rx="1" />
              <path
                stroke="#BBB"
                strokeWidth="1.5"
                d="M3 6.106c-.017 0 4.543.003 5 0v-3.66C8 1.094 6.88 0 5.5 0S3 1.095 3 2.445v3.66z"
              />
            </g>
          </svg>
        </span>
      </div>
    );
  }

  return (
    <div
      onClick={() => {
        handleOdd({ match: match, odd: odd, isSelected: isSelected });
      }}
      className={`${isHaveError && "bg-red-500"} ${isAvailable ? "pointer-events-none bg-gray-400 opacity-50 " : ""} ${
        isSelected ? "bg-focusBg" : "bg-buttonbg"
      } flex relative items-center border border-gray-300 rounded h-8 py-1 text-detailText px-1 cursor-pointer w-[30%] justify-between`}
    >
      <span className={`text-gray-700 text-xxs p-1 ${isSelected ? "text-white" : "text-black"} `}>{odd.name}</span>
      <span className={` gap-2 text-xs font-semibold ${isSelected ? "text-white" : "text-black"} text-center`}>
        {odd.value}
      </span>
    </div>
  );
});

const IFrame = memo(({ tid, sportName }) => {
  const tabIcons = {
    1: detail1,
    2: detail2,
    3: detail3,
    4: detail4,
    5: detail5,
    6: detail6,
  };
  const tabLabels = {
    1: "Simulation",
    2: "Live-Statistics",
    3: "Live Goal",
    4: "Setup",
    5: "Liveticker",
    6: "Statistics",
  };
  const [activeTab, setActiveTab] = useState(2);
  if (tid) {
    return tid && <iframe src={tid} style={{ width: "100%", height: "300px" }} className="overflow-hidden"></iframe>;
  }

  return (
    <>
      <ul className="flex flex-nowrap overflow-x-auto text-sm p-2 h-14 font-medium justify-center bg-white text-center text-black dark:text-gray-400">
        {[2, 3, 4, 5, 6].map((tab) => (
          <li className="me-2" key={tab}>
            <button
              onClick={() => setActiveTab(tab)}
              className={`inline-block w-12 rounded-lg ${
                activeTab === tab ? "text-gray-300" : "dark:hover:text-white"
              }`}
            >
              <div className="flex flex-col items-center">
                {<img src={tabIcons[tab]} />}
                <div className={`text-black text-nowrap ${activeTab === tab ? "text-lg" : "text-xxs text-gray-500"}`}>
                  {activeTab === tab ? "▲" : tabLabels[tab]}
                </div>
              </div>
            </button>
          </li>
        ))}
      </ul>

      {activeTab !== null && (
        <div className="flex items-center justify-center h-52 text-gray-700 bg-white p-4 shadow">
          {activeTab === 1
            ? "Tab 1 içeriği"
            : activeTab === 2
            ? "Tab 2 içeriği"
            : activeTab === 3
            ? "Tab 3 içeriği"
            : activeTab === 4
            ? "Tab 4 içeriği"
            : activeTab === 5
            ? "Tab 5 içeriği"
            : "Tab 6 içeriği"}
        </div>
      )}
    </>
  );
});

const Header = memo(
  ({ isLive, home_team_name, home_team_logo, away_team_name, away_team_logo, match_time, sportId, matchId }) => {
    if (isLive) {
      if (sportId === 1) {
        return (
          <ScoreMapFootball
            home_team_name={home_team_name}
            home_team_logo={home_team_logo}
            away_team_name={away_team_name}
            away_team_logo={away_team_logo}
            matchId={matchId}
          />
        );
      } else if (sportId === 2) {
        return <ScoreMapBasketball home_team_name={home_team_name} away_team_name={away_team_name} matchId={matchId} />;
      } else {
        return <ScoreMapTennis home_team_name={home_team_name} away_team_name={away_team_name} matchId={matchId} />;
      }
    } else {
      return (
        <div className="flex justify-between items-center text-center mt-1 mb-2">
          {/* Sol Bilgi */}
          <div className="flex-1">
            <img src={home_team_logo ? home_team_logo : shirt1} className="mx-auto mb-1" style={{ height: "30px" }} />
            <div className="text-xs font-bold">{home_team_name}</div>
          </div>

          {/* Orta Bilgi */}
          <div className="flex-1">
            <div className="text-lg text-gray-400 font-bold">{moment(match_time * 1000).format("hh:mm")}</div>
            <div className="text-xs text-gray-400 font-bold">{moment(match_time * 1000).format("dddd")}</div>
          </div>

          {/* Sağ Bilgi */}
          <div className="flex-1">
            <img src={away_team_logo ? away_team_logo : shirt2} className="mx-auto mb-1" style={{ height: "30px" }} />
            <div className="text-xs font-bold">{away_team_name}</div>
          </div>
        </div>
      );
    }
  }
);

const ScoreMapFootball = ({ home_team_name, home_team_logo, away_team_name, away_team_logo, matchId }) => {
  const scores_map = useSelector((state) => state?.livematches?.scores?.[matchId]);
  const time = useSelector((state) => state?.livematches?.matchesTimer?.[matchId]);
  return (
    <div className="Event-styles-module-event-info-header-desktop-margins">
      <section className="SoccerLive-styles-module-content">
        <div className="SoccerLive-styles-module-content-wrapper">
          <div className="SoccerLive-styles-module-team">
            <div className="SoccerLive-styles-module-team-data">
              <img
                className="text-center item-center"
                src={home_team_logo ? home_team_logo : shirt1}
                alt=""
                width={30}
                height={30}
              />
              <div className="SoccerLive-styles-module-team-name">{home_team_name}</div>
            </div>
            <div className="SoccerLive-styles-module-team-cards"></div>
          </div>
          <div className="SoccerLive-styles-module-team-score">
            <span className="SoccerLive-styles-module-square text-black">{scores_map?.homeTotalScore}</span>
            <span className="SoccerLive-styles-module-separator">:</span>
            <span className="SoccerLive-styles-module-square text-black">{scores_map?.awayTotalScore}</span>
          </div>
          <div className="SoccerLive-styles-module-team SoccerLive-styles-module-team-right">
            <div className="SoccerLive-styles-module-team-cards SoccerLive-styles-module-right"></div>
            <div className="SoccerLive-styles-module-team-data">
              <img src={away_team_logo ? away_team_logo : shirt2} alt="" width={30} height={30} />
              <div className="SoccerLive-styles-module-team-name">{away_team_name}</div>
            </div>
          </div>
        </div>
        <div className="SoccerLive-styles-module-info">
          <div className="SoccerLive-styles-module-time">
            <span className="SoccerLive-styles-module-clock">
              <span>{time}</span>
            </span>
          </div>
        </div>
      </section>
    </div>
  );
};

const ScoreMapBasketball = ({ home_team_name, away_team_name, matchId }) => {
  const scoreMap = useSelector((state) => state?.livematches?.scores?.[matchId]);
  const time = useSelector((state) => state?.livematches?.matchesTimer?.[matchId]);

  if (!scoreMap || scoreMap === null) {
    return null;
  }

  return (
    <div className="Event-styles-module-event-info-header-desktop-margins">
      <section className="flex BasketballLive-styles-module-scorecenter md:p-4">
        <div className="BasketballLive-styles-module-teams">
          <div className="BasketballLive-styles-module-date-time">
            <span>{time}</span>&nbsp;
          </div>
          <div className="BasketballLive-styles-module-team">
            <div className="BasketballLive-styles-module-name">{home_team_name}</div>
          </div>
          <div className="BasketballLive-styles-module-team">
            <div className="BasketballLive-styles-module-name">{away_team_name}</div>
          </div>
        </div>
        <div className="BasketballLive-styles-module-score">
          <table>
            <thead>
              <tr>
                {scoreMap?.periods.map((e, index) => (
                  <td key={index}>{index + 1}</td>
                ))}
                <td>T</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                {scoreMap?.periods.map((scoreList, index) => {
                  return (
                    <td key={index} className="OtherScoreList-styles-module-highlighted">
                      <span>{scoreList.homeScore}</span>
                    </td>
                  );
                })}
                <td className="OtherScoreList-styles-module-highlighted">
                  <span>{scoreMap?.homeTotalScore}</span>
                </td>
              </tr>
              <tr>
                {scoreMap?.periods.map((scoreList, index) => {
                  return (
                    <td key={index} className="OtherScoreList-styles-module-highlighted">
                      <span>{scoreList.awayScore}</span>
                    </td>
                  );
                })}
                <td className="OtherScoreList-styles-module-highlighted">
                  <span>{scoreMap?.awayTotalScore}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

const ScoreMapTennis = ({ home_team_name, away_team_name, matchId }) => {
  const scoreMap = useSelector((state) => state?.livematches?.scores?.[matchId]);
  const time = useSelector((state) => state?.livematches?.matchesTimer?.[matchId]);
  if (!scoreMap) {
    return null;
  }
  return (
    <div className="Event-styles-module-event-info-header-desktop-margins">
      <section>
        <div className="TennisLive-styles-module-scorecenter p-2">
          <div className="TennisLive-styles-module-teams">
            <div className="TennisLive-styles-module-date-time">{time}</div>
            <div className="TennisLive-styles-module-team">
              <div className="TennisLive-styles-module-name">{home_team_name}</div>
            </div>
            <div className="TennisLive-styles-module-team">
              <div className="TennisLive-styles-module-name">{away_team_name}</div>
            </div>
          </div>
          <div className="TennisLive-styles-module-score">
            <table>
              <thead>
                <tr>
                  {scoreMap.periods.map((e, index) => (
                    <td key={index}>{index + 1}</td>
                  ))}
                  <td>T</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {scoreMap.periods.map((scoreList, index) => {
                    return (
                      <td key={index} className="OtherScoreList-styles-module-highlighted">
                        <span>{scoreList.homeScore}</span>
                      </td>
                    );
                  })}
                  <td className="OtherScoreList-styles-module-highlighted">
                    <span>{scoreMap?.homeTotalScore}</span>
                  </td>
                </tr>
                <tr>
                  {scoreMap.periods.map((scoreList, index) => {
                    return (
                      <td key={index} className="OtherScoreList-styles-module-highlighted">
                        <span>{scoreList.awayScore}</span>
                      </td>
                    );
                  })}
                  <td className="OtherScoreList-styles-module-highlighted">
                    <span>{scoreMap.awayTotalScore}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
};
