// src/layouts/MainLayout.js
import React from "react";
import Footer from "../../components/Footer";
import { RightSidebar } from "../../components/Sidebar/RightSidebar";
import { BottomAppbar } from "../../components/BottomAppbar";
import { LeftSideBar } from "../../components/Sidebar/LeftSidebar";
import HomePageHeader from "../../components/Headers/HomePageHeader";
import { useState } from "react";
// import { Outlet } from 'react-router-dom';

const MainLayout = ({ children }) => {
  const [headerTitle, setHeaderTitle] = useState("home");
  const handleTitleChange = (newTitle) => {
    setHeaderTitle(newTitle);
  };
  return (
    <div className="flex flex-col min-h-screen font-Source-Code-Pro ">
      <HomePageHeader headerTitle={headerTitle} handleTitleChange={handleTitleChange} />
      <div className="flex flex-grow md:mt-14">
        <LeftSideBar headerTitle={headerTitle} handleTitleChange={handleTitleChange} />
        <main className="flex-1 overflow-x-auto h-[100vh] ">{children}</main>
        <RightSidebar />
      </div>
      {/* <div className="md:hidden">
        <LeftSideBar headerTitle={headerTitle} handleTitleChange={handleTitleChange} />
        <main className="flex-1 overflow-x-auto h-[100vh] ">{children}</main>
        <RightSidebar />
      </div> */}
      <BottomAppbar />
      <Footer />
    </div>
  );
};

export default MainLayout;
