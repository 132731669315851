const initialState = {
  isAuthenticated: false,
  data: [],
  counts: [],
  error: null,
};

export const ApiSportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SPORTS_SUCCESS":
      let asd = [];
      action?.payload?.data?.forEach((sport) => {
        const sportName = action?.payload?.counts.find((item) => item.sport_id === sport.id);
        asd.push({ ...sport, length: sportName?.total_count });
      });
      return {
        ...state,
        isAuthenticated: true,
        data: action.payload,
        counts: asd,
        error: null,
      };
    case "SPORTS_FAILURE":
      return {
        ...state,
        isAuthenticated: false,
        data: null,
        error: action.payload,
      };
    default:
      return state;
  }
};
