export function sortAvailableOdds(availableOddsMap) {
  return Object.fromEntries(
    Object.entries(availableOddsMap).map(([key, value]) => {
      const sortedCaptions = value.captions.sort((a, b) => {
        const order = { Under: 1, Over: 0 };
        if (order[a] !== undefined || order[b] !== undefined) {
          return (order[a] || 2) - (order[b] || 2);
        }
        const valueA = a.charAt(0) === "X" ? 1.5 : parseFloat(a) || a;
        const valueB = b.charAt(0) === "X" ? 1.5 : parseFloat(b) || b;
        return valueA - valueB;
      });
      return [key, { ...value, captions: sortedCaptions }];
    })
  );
}

export function sortingOddsMap2(data) {
  const sortedKeys = Object.keys(data).sort((a, b) => {
    return data[a]._mappedIndex - data[b]._mappedIndex;
  });
  const sortedData = {};
  sortedKeys.forEach((key) => {
    console.log(key, data[key]);
    sortedData[key] = data[key];
  });
  return sortedData;
}

export function sortingOddsMap(data) {
  const sortedKeys = Object.keys(data).sort((a, b) => {
    const order = { Under: 1, Over: 0 };
    if (order[a] !== undefined || order[b] !== undefined) {
      return (order[a] || 2) - (order[b] || 2);
    }
    const valueA = a.charAt(0) === "X" ? 1.5 : parseFloat(a) || a;
    const valueB = b.charAt(0) === "X" ? 1.5 : parseFloat(b) || b;
    return valueA - valueB;
  });

  const sortedData = sortedKeys.reduce((acc, key) => {
    const sortedArray = data[key].sort((a, b) => {
      const order = { Under: 1, Over: 0 };
      if (order[a.name] !== undefined || order[b.name] !== undefined) {
        return (order[a.name] || 2) - (order[b.name] || 2);
      }
      const valueA = a.name.charAt(0) === "X" ? 1.5 : parseFloat(a.name) || a.name;
      const valueB = b.name.charAt(0) === "X" ? 1.5 : parseFloat(b.name) || b.name;
      return valueA - valueB;
    });
    acc[key] = sortedArray;
    return acc;
  }, {});
  return sortedData;
}
