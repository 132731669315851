import React, { useEffect, useState } from "react";
import { logo } from "../../assets/images";
import { Link } from "react-router-dom";
import { Button } from "../Button/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/actions/authActions";
import { FaRegUserCircle, FaRegEye, FaRegEyeSlash, FaChevronLeft } from "react-icons/fa";
import { IoNotificationsOutline } from "react-icons/io5";
import { ImDrawer } from "react-icons/im";
import { setSelectedItem } from "../../store/actions/sidebarActions";
import { useLocation } from "react-router-dom";
import { balance_Url } from "../../store/paths";
function Header({ title }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isBalanceVisible, setIsBalanceVisible] = useState(true);
  const navigate = useNavigate();
  const storedUserData = JSON.parse(localStorage.getItem("authToken"));
  const userName = storedUserData?.user?.user?.name || "User Name";
  const location = useLocation();
  const [balance, setBalance] = useState(() => {
    const savedBalance = localStorage.getItem("balance");
    return savedBalance ? JSON.parse(savedBalance) : 0;
  });
  useEffect(() => {
    const getBalance = async () => {
      try {
        const storedUserData = JSON.parse(localStorage.getItem("authToken"));
        const token = storedUserData?.user?.token;
        if (!token) {
          return;
        }

        const response = await fetch(balance_Url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          dispatch(logout());
          return;
        }

        const data = await response.json();
        setBalance(data);
        localStorage.setItem("balance", JSON.stringify(data));
      } catch (error) {
        console.error("Veri çekilirken hata oluştu:", error.message);
      }
    };

    if (userName) {
      const timeoutId = setInterval(getBalance, 2000);
      return () => clearInterval(timeoutId);
    }
  }, [userName]);

  const useAuth = () => {
    const authToken = localStorage.getItem("authToken");
    return authToken ? JSON.parse(authToken) : { isAuthenticated: false };
  };

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const { isAuthenticated } = useAuth();
  const ids = storedUserData?.user?.user?.id;
  const showDetail = useSelector((state) => state?.homereducer?.showDetailLive);

  return (
    <>
      {/* Overlay - only shown when dropdown is open */}
      {isDropdownOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-40"
          onClick={() => setIsDropdownOpen(false)} // Close dropdown when clicking on overlay
        ></div>
      )}

      {/* Desktop Header - Hidden on Mobile */}
      <div className="hidden sm:block h-full">
        <nav className="flex items-center justify-between w-full px-4 bg-primary-600 shadow bg-[#c8102e] h-14 fixed z-50 top-0 left-0 max-w-[1920px]">
          <div className="flex items-center justify-start w-full px-4 md:px-8 gap-12 lg:gap-24">
            <Link to="/" className="logo" onClick={() => dispatch(setSelectedItem(undefined))}>
              <img src={logo} alt="logo" width={90} height={35} />
            </Link>
            <ul className="flex text-white font-normal text-xs lg:text-sm gap-3 md:gap-6">
              <li>
                <Link to="/">Sports Betting</Link>
              </li>
              <li>
                <Link to="/live-betting">Live Betting</Link>
              </li>
              <li>
                <Link to="http://38.180.122.61:4002/">Games</Link>
              </li>
            </ul>
          </div>

          <div>
            <ul className="flex gap-2">
              {isAuthenticated ? (
                <li>
                  <div className="flex items-center space-x-6">
                    {/* Balance Display */}
                    <div className="bg-red-900 px-6 py-1 whitespace-nowrap justify-items-center rounded text-sm text-gray-300">
                      <span>Balance</span>
                      <p className="font-normal text-white">{new Intl.NumberFormat("tr-TR", { style: "currency", currency: "EUR" }).format(balance)}</p>
                    </div>

                    {/* Icons */}
                    <div className="flex items-center space-x-7 text-white">
                      <Link to="/meinWetten" className="text-center place-items-center">
                        <ImDrawer size={25} className="text-gray-300" />
                        <span className="text-xs text-gray-300 whitespace-nowrap">My Bets</span>
                      </Link>
                      <Link to="/news" className="text-center place-items-center">
                        <IoNotificationsOutline size={23} className="text-gray-300" />
                        <span className="text-xs text-gray-300">News</span>
                      </Link>
                      <Link to="/userPage" className="text-center place-items-center cursor-pointer relative">
                        <FaRegUserCircle size={23} className="text-gray-300" />
                        <span className="text-xs text-gray-300">Account</span>
                      </Link>
                    </div>
                  </div>
                </li>
              ) : (
                <>
                  <li>
                    <Link to="/register">
                      <button variant="primary" className="bg-white w-24 h-10 rounded-md font-extralight text-red-800 hover:text-red-700 hover:bg-gray-200">
                        Register
                      </button>
                    </Link>
                  </li>
                  <li>
                    <Link to="/login">
                      <button
                        variant="primary"
                        className="bg-black w-24 h-10 rounded-md bg-opacity-40 font-extralight text-white text-opacity-80 hover:text-gray-300 hover:bg-opacity-20"
                      >
                        Login
                      </button>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </nav>
      </div>
      {!showDetail && (
        <header className="block md:hidden sticky top-0 z-20">
          <div className="flex justify-between items-center w-full bg-[#c8102e] h-[45px] px-4">
            <Link to="/" onClick={() => dispatch(setSelectedItem(undefined))}>
              <FaChevronLeft className="text-white" />
            </Link>
            <div className="flex justify-center items-center w-full bg-[#c8102e] px-4">
              {location.pathname === "/my-bet" ? (
                <div className="text-white text-sm font-normal">My Bets</div>
              ) : location.pathname === "/live-betting" ? (
                <div className="text-white text-sm font-normal">Live {title}</div>
              ) : null}
            </div>

            {isAuthenticated ? (
              <div className="flex items-center space-x-4">
                <div className="relative">
                  <div onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="flex flex-col items-center cursor-pointer">
                    <FaRegUserCircle size={20} className="text-white" />
                    <p className="font-normal text-xs text-white">{new Intl.NumberFormat("tr-TR", { style: "currency", currency: "EUR" }).format(balance)}</p>
                  </div>

                  {/* Dropdown Menu */}
                  {isDropdownOpen && (
                    <div className="absolute top-full right-[-30%] mt-2 w-64 bg-white rounded-md shadow-lg z-50 p-4">
                      <div className="text-center font-semibold text-gray-800">{ids}</div>
                      <div className="flex items-center border-t border-gray-300 justify-between mt-4">
                        <div className="mt-3 flex">
                          <span className="text-gray-600 mr-7 ">Kontoguthaben</span>
                          <div className="flex items-center">
                            <button onClick={() => setIsBalanceVisible(!isBalanceVisible)} className="focus:outline-none">
                              {isBalanceVisible ? <FaRegEye /> : <FaRegEyeSlash />}
                            </button>
                            <span className="ml-2 font-semibold text-gray-800">{isBalanceVisible ? balance : "****"}</span>
                          </div>
                        </div>
                      </div>

                      <button className="w-full bg-green-600 hover:bg-green-700 text-white font-semibold py-2 rounded mt-4">Einzahlung</button>

                      <div className="mt-4 border-t pt-2">
                        <Link to="/userPage" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                          Mein Konto
                        </Link>
                        <Link to="/news" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                          Neuigkeiten
                        </Link>
                        <Link to="/payout" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                          Auszahlung
                        </Link>
                        <Link to="/help" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                          Hilfe
                        </Link>
                        <Link to="/settings" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                          Einstellungen
                        </Link>

                        <button onClick={handleLogout} className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                          Logout
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <Link to="/login">
                <Button variant="primaryOutLine" className="font-extralight">
                  Login
                </Button>
              </Link>
            )}
          </div>
        </header>
      )}
    </>
  );
}

export default Header;
