import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { TopMatchesApi } from "../../store/actions/top_matches";
import { GenericMainPageBody, MobileGenericMainPageBody } from "../Generic/GenericBody";
import { MatchData_URL } from "../../store/paths";
import { FilteredHomeApi } from "../../store/actions/filteredHomeAction";

export const TopGames = ({ showDetail, inMain = false }) => {
  const dispatch = useDispatch();
  const body = {
    pageNumber: 1,
    pageSize: 100,
    hours: 24,
  };
  useEffect(() => {
    dispatch(FilteredHomeApi(body));
  }, [dispatch]);

  return (
    <MobileGenericMainPageBody type={"filteredMatches"} showDetail={showDetail} className={"md:mt-8"} isLive={false} />
  );
};
