import LoginPage from "./app/pages/auth/Login";
import RegisterPage from "./app/pages/auth/RegisterPage";
import MyBets2 from "./app/pages/MyBet/page";
import BetGuide from "./app/pages/bet-guide/page";
import HomePage from "./app/pages/default/Homepage";
import LiveBetting from "./app/pages/livebetting/page";
import { UserMain } from "./app/pages/user-page/userMain";
import Payout from "./app/pages/user-page/payout";
import MyBets from "./app/pages/user-page/userWetten";
import { MobilDynamicMenus } from "./app/pages/default/mobilDynamicMenu";
import { FootballMenu } from "./app/pages/default/footballMenu";
import { BasketballMenu } from "./app/pages/default/basketballMenu";
import { TopEvents } from "./components/LandingPage/Top_Evetns";
import { TopGames } from "./components/LandingPage/Today_Games";

const routes = [
  {
    path: "/login",
    element: LoginPage,
    isProtected: false,
  },
  {
    path: "/register",
    element: RegisterPage,
    isProtected: false,
  },
  {
    path: "/my-bet",
    element: MyBets2,
    isProtected: false,
  },
  {
    path: "*",
    element: HomePage,
    isProtected: false,
  },
  {
    path: "/live-betting",
    element: LiveBetting,
    isProtected: false,
  },
  {
    path: "/top-events",
    element: TopEvents,
    isProtected: false,
  },
  {
    path: "/last-minutes",
    element: TopGames,
    isProtected: false,
  },
  {
    path: "/bet-guide",
    element: BetGuide,
    isProtected: false,
  },
  {
    path: "/userPage",
    element: UserMain,
    isProtected: true,
  },
  {
    path: "/payout",
    element: Payout,
    isProtected: false,
  },
  {
    path: "/meinWetten",
    element: MyBets,
    isProtected: true,
  },
  {
    path: "/mobilDynamic",
    element: MobilDynamicMenus,
    isProtected: false,
  },
  {
    path: "/footballMenus",
    element: FootballMenu,
    isProtected: false,
  },
  {
    path: "/basketballMenus",
    element: BasketballMenu,
    isProtected: false,
  },
];

export default routes;
