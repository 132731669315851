import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaChevronLeft, FaChevronRight, FaRegEye, FaRegEyeSlash, FaRegUserCircle } from "react-icons/fa";
import { TMTopMatchesBody } from "../../../components/TopMatches/TopMatchesBody";
import { updateRequestBody } from "../../../store/actions/homeTypes";
import { setSelectedItem } from "../../../store/actions/sidebarActions";
import { FilteredHomeBody } from "../../../components/FilteredHome/FilteredHomeBody";
import { FilteredHomeApi } from "../../../store/actions/filteredHomeAction";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { logout } from "../../../store/actions/authActions";
import { Button } from "../../../components/Button/Button";
import { calendar, combination, earth, german } from "../../../assets/images";
import { FcAlarmClock } from "react-icons/fc";
import { LiveIcon, ThreeStd, FourEightStd, LiveIcons } from "../../../components/LandingPage/liveIcons";
import { balance_Url, imagesUrl, navigationTreeUrl } from "../../../store/paths";

const quickLinks = [
  {
    icon: FcAlarmClock,
    title: "Live",
    value: "live-betting",
  },
  {
    icon: calendar,
    title: "Today",
    value: "calendar",
  },
  {
    title: "3 Std",
    value: "3-std",
  },

  {
    title: "48 Std",
    value: "48-std",
  },
];

export const BasketballMenu = ({ activeMenu, showDetail, menu, data, onSelectionChange }) => {
  const dispatch = useDispatch();
  const [menus, setMenus] = useState(null);
  const [expandedMenu, setExpandedMenu] = useState([]);
  const [selectedTournamentId, setSelectedTournamentId] = useState(null);
  const [betTab, setBetTab] = useState(null);
  const [selectedOddsIds, setSelectedOddsIds] = useState([]);
  const [dropDownCount, setDropDownCount] = useState(5);
  const matches = useSelector((state) => state?.filteredMatches?.matches);
  const tournamentMatchMap = useSelector((state) => state?.filteredMatches?.tournamentMatchMap);
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isBalanceVisible, setIsBalanceVisible] = useState(true);
  const storedUserData = JSON.parse(localStorage.getItem("authToken"));
  const userName = storedUserData?.user?.user?.name || "User Name";
  const useAuth = () => {
    const authToken = localStorage.getItem("authToken");
    return authToken ? JSON.parse(authToken) : { isAuthenticated: false };
  };
  const { isAuthenticated } = useAuth();
  const ids = storedUserData?.user?.user?.id;
  const [balance, setBalance] = useState();

  useEffect(() => {
    const getBalance = async () => {
      try {
        const storedUserData = JSON.parse(localStorage.getItem("authToken"));
        const token = storedUserData?.user?.token;
        if (!token) {
          return;
        }

        const response = await fetch(balance_Url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) throw new Error("Network response was not ok");

        const data = await response.json();
        setBalance(data);
      } catch (error) {
        console.error("Veri çekilirken hata oluştu:", error.message);
      }
    };
    if (userName) {
      const timeoutId = setInterval(getBalance, 2000);
      return () => clearInterval(timeoutId);
    }
  }, []);

  const maxDropDownCount = useSelector((state) => state?.filteredMatches?.dropDownCount);
  const availableSports = useSelector((state) => state?.filteredMatches?.availableSports);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const sportsArray = useMemo(() => {
    if (!availableSports) {
      return [];
    }
    const array = Object.entries(availableSports).map(([id, sport]) => ({
      id,
      ...sport,
    }));
    if (array.length === 0) return [];

    setBetTab(array[0].id);
    return array;
  }, [availableSports]);

  useEffect(() => {
    fetch(navigationTreeUrl)
      .then((response) => response.json())
      .then((data) => {
        const basketballMenu = data.find((menu) => menu.name.toLowerCase() === "basketball");
        setMenus(basketballMenu ? { children: [basketballMenu] } : null);
        setExpandedMenu(basketballMenu ? [basketballMenu] : []);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleMenuClick = (menu) => {
    if (menu.children.length !== 0) {
      setExpandedMenu((prev) => [...prev, menu]);
    } else {
      dispatch(
        FilteredHomeApi({
          pageNumber: 1,
          pageSize: 100,
          tournament_ids: [menu.tree_value],
        })
      );
      setSelectedTournamentId(menu.id);
      setBetTab(menu.id);
    }
  };

  const handleBackClick = () => {
    if (selectedTournamentId) {
      setSelectedTournamentId(null);
    } else if (expandedMenu.length > 1) {
      setExpandedMenu((prev) => prev.slice(0, -1));
    } else {
      navigate("/");
    }
  };

  const updateDropDownCount = () => {
    const width = window.innerWidth;
    let count;

    if (width < 650) {
      count = 1;
    } else if (width < 1000) {
      count = 2;
    } else if (width < 1250) {
      count = 3;
    } else if (width < 1500) {
      count = 4;
    } else {
      count = maxDropDownCount;
    }
    if (showDetail) {
      count -= 2;
    }
    setDropDownCount(count);
  };

  useEffect(() => {
    updateDropDownCount();
    window.addEventListener("resize", updateDropDownCount);
    return () => {
      window.removeEventListener("resize", updateDropDownCount);
    };
  }, [maxDropDownCount, showDetail]);

  const currentMenu = expandedMenu.length > 0 ? expandedMenu[expandedMenu.length - 1] : menus;

  const headerTitle = expandedMenu.length > 0 && expandedMenu[0].parent_id === null ? expandedMenu[0].name : "Sports";
  const headerCountry =
    expandedMenu.length > 0 && expandedMenu[expandedMenu.length - 1].tree_key === "country" ? expandedMenu[expandedMenu.length - 1].name : null;

  const tournamentMatches = tournamentMatchMap
    ? Object.keys(tournamentMatchMap).reduce((acc, tournamentId) => {
        const tournamentMatches = matches?.filter((match) => tournamentMatchMap[tournamentId]?.includes(match.id));
        return [...acc, ...tournamentMatches];
      }, [])
    : [];

  if (!menus) {
    return <div>Yükleniyor...</div>;
  }

  return (
    <>
      {isDropdownOpen && <div className="fixed inset-0 bg-black opacity-50 z-40" onClick={() => setIsDropdownOpen(false)}></div>}
      <div className="flex flex-col place-content-center h-[7%] bg-headerBack p-2">
        <div className="flex items-center justify-between w-full ">
          <button onClick={handleBackClick} className="text-white text-lg">
            <FaChevronLeft />
          </button>
          <div className="flex flex-col items-center justify-center h-full">
            <span className="text-white font-semibold text-lg text-center">{headerTitle}</span>
            <span className="text-white text-sm mt-1 text-center">{headerCountry}</span>
          </div>

          {isAuthenticated ? (
            <div className="flex items-center space-x-4">
              <div className="relative">
                <div onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="flex flex-col items-center cursor-pointer">
                  <FaRegUserCircle size={20} className="text-white" />
                  <div className="text-xs text-white mt-1">{balance} €</div>
                </div>

                {isDropdownOpen && (
                  <div className="absolute top-full right-[-30%] mt-2 w-64 bg-white rounded-md shadow-lg z-50 p-4">
                    <div className="text-center font-semibold text-gray-800">{ids}</div>
                    <div className="flex items-center border-t border-gray-300 justify-between mt-4">
                      <div className="mt-3 flex">
                        <span className="text-gray-600 mr-7 ">Kontoguthaben</span>
                        <div className="flex items-center">
                          <button onClick={() => setIsBalanceVisible(!isBalanceVisible)} className="focus:outline-none">
                            {isBalanceVisible ? <FaRegEye /> : <FaRegEyeSlash />}
                          </button>
                          <span className="ml-2 font-semibold text-gray-800">{isBalanceVisible ? balance : "****"}</span>
                        </div>
                      </div>
                    </div>

                    <button className="w-full bg-green-600 hover:bg-green-700 text-white font-semibold py-2 rounded mt-4">Einzahlung</button>

                    <div className="mt-4 border-t pt-2">
                      <Link to="/userPage" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                        Mein Konto
                      </Link>
                      <Link to="/news" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                        Neuigkeiten
                      </Link>
                      <Link to="/payout" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                        Auszahlung
                      </Link>
                      <Link to="/help" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                        Hilfe
                      </Link>
                      <Link to="/settings" className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                        Einstellungen
                      </Link>

                      <button onClick={handleLogout} className="block py-2 text-gray-700 hover:bg-gray-100 rounded">
                        Logout
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <Link to="/login">
              <Button variant="primaryOutLine" className="font-extralight hover:bg-transparent">
                Login
              </Button>
            </Link>
          )}
        </div>
      </div>
      <div className="w-[100%]">
        <ul className="flex flex-row md:flex-col overflow-x-scroll space-x-0.5 md:space-x-0 md:space-y-2 md:hidden">
          {quickLinks.map((row, index) => (
            <li
              className={`flex flex-col md:flex-row items-center md:rounded h-[70px] text-center place-content-center mb-2 cursor-pointer bg-white p-1 hover:bg-white w-full`}
              key={index}
            >
              {row.value === "live-betting" ? (
                <Link to="/live-betting" className="center">
                  <LiveIcons className="md:mr-3  md:h-[20px]" />
                  <span className="text-xs md:text-sm text-center">{row.title}</span>
                </Link>
              ) : row.value === "calendar" ? (
                <div className="flex flex-col items-center md:flex-row">
                  <img src={row.icon} width={25}></img>
                  <span className="text-xs md:text-sm mt-2 text-nowrap text-center">{row.title}</span>
                </div>
              ) : row.value === "3-std" ? (
                <div className="flex flex-col items-center md:flex-row">
                  <ThreeStd className="md:mr-3 md:h-[20px]" />
                  <span className="text-xs md:text-sm  mt-1 text-nowrap text-center">{row.title}</span>
                </div>
              ) : row.value === "48-std" ? (
                <div className="flex flex-col items-center md:flex-row">
                  <FourEightStd className="md:mr-3  md:h-[20px]" />
                  <span className="text-xs md:text-sm mt-1 text-nowrap text-center">{row.title}</span>
                </div>
              ) : null}
            </li>
          ))}
        </ul>
      </div>

      {!selectedTournamentId && (
        <ul className="">
          {currentMenu.children.map((menu, index) => (
            <li
              key={index}
              className="menu-item flex items-center justify-between px-4 h-14 pr-0 bg-white shadow-sm cursor-pointer hover:bg-gray-100"
              onClick={() => handleMenuClick(menu)}
            >
              <div className="flex  items-center gap-6">
                <div className="">
                  <img
                    src={menu.icon.includes("https://") ? menu.icon : imagesUrl + (menu.icon + "").toLowerCase().replace(" ", "-") + ".png"}
                    alt="icon"
                    className="w-5 h-5 rounded-xl"
                  />
                </div>
                <div className="border-b flex w-[52vh]">
                  <div className="flex flex-col place-content-center h-12 mb-1">
                    <span className="text-md  md:text-sm ">{menu.name}</span>
                  </div>
                  <span className="flex items-center text-gray-600 ml-auto pr-2 text-xs md:text-sm mb-1">
                    {menu.meta[0].value}
                    <FaChevronRight className="ml-2 text-gray-400 text-sm" />
                  </span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}

      {selectedTournamentId && (
        <div className="grid grid-cols-12">
          <div className="col-span-12 bg-white md:bg-custom overflow-auto scrollbar-hide">
            <div className="shadow-topCombisShadow">
              <div className="flex items-center justify-between w-full py-4 px-4 md:px-2"></div>

              <div className="grid">
                <FilteredHomeBody
                  betTab={betTab}
                  showDetail={showDetail}
                  selectedOddsIds={selectedOddsIds}
                  setSelectedOddsIds={setSelectedOddsIds}
                  dropDownCount={dropDownCount}
                  filteredMatches={tournamentMatches}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
