import { GenericMinutes, LiveGenericMinutes } from "../GenericMinutes";
import { GenericScores } from "../GenericScores";
import { GenericTeamNames } from "../GenericTeamNames";
import { GenericOddButtons } from "../../Button/GenericOddButton";
import { GenericDetails } from "../GenericDetails";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showDetailLive } from "../../../store/actions/homeTypes";

export const LiveGenericMatchesRow = memo(
  ({ type, isLive, matchId, sportId, periodId, status, matchTime, home_team_name, home_team_logo, away_team_name, away_team_logo, selectedOddsIds }) => {
    const dispatch = useDispatch();
    const handleDetail = () => {
      dispatch(
        showDetailLive({
          type: type,
          matchId: matchId,
          isLive: isLive,
        })
      );
    };
    const showDetail = useSelector((state) => state?.homereducer?.showDetailLive);
    return (
      <div className="col-span-12 h-[85px] md:h-14 pl-2 grid grid-cols-12 lg:grid-cols-12 cursor-pointer bg-white hover:bg-gray-100 items-center px-4 md:px-6 border-b border-gray-200">
        <div onClick={handleDetail} className={`${showDetail ? "md:col-span-4" : "md:col-span-5"} col-span-11 grid grid-cols-11`}>
          {isLive ? <LiveGenericMinutes match_Id={matchId} periodId={periodId} status={status} sport_id={sportId} /> : <GenericMinutes matchTime={matchTime} />}
          <div className="flex justify-between col-span-9 ml-[6%] md:ml-0">
            <GenericTeamNames
              home_team_name={home_team_name}
              home_team_logo={home_team_logo}
              away_team_name={away_team_name}
              away_team_logo={away_team_logo}
              matchId={matchId}
            />
          </div>
          {isLive && <GenericScores matchId={matchId} sportId={sportId} />}
        </div>

        <div className={`${showDetail ? "md:col-span-8" : "md:col-span-7"} col-span-12 md:mb-0 mb-2 grid grid-cols-12`}>
          <div className={`col-span-11 grid  grid-cols-${selectedOddsIds.length}  gap-2`}>
            <GenericOddButtons
              type={type}
              matchId={matchId}
              home_team_name={home_team_name}
              away_team_name={away_team_name}
              selectedOddsIds={selectedOddsIds}
            />
          </div>
          <GenericDetails className={"col-span-1 items-center flex justify-end"} type={type} matchId={matchId} handleDetail={handleDetail} />
        </div>
      </div>
    );
  }
);
