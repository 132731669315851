import { useSelector } from "react-redux";
import { GenericOddNamesGroup } from "./GenericOddNamesGroup";
import { GenericMatchesRow } from "./GenericMatchesRow";
import { useCallback, useMemo, useState } from "react";
import { GenericComboboxGroup } from "./GenericComboboxGroup";
import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import CustomSwitch from "../CustomSwitch";
import { PiSlidersHorizontalLight } from "react-icons/pi";
import { MatchSkeleton } from "../Skeleton/GenericSkeleton";
import { imagesUrl } from "../../store/paths";

export const GenericMainPageBody = ({ type, showDetail, className, title, isLive, allEventsLink }) => {
  const [betTab, setBetTab] = useState();

  const availableSports = useSelector((state) => state?.[type]?.availableSports);
  const sportsArray = useMemo(() => {
    if (!availableSports) {
      return [];
    }
    const array = Object.entries(availableSports).map(([id, sport]) => ({
      id,
      ...sport,
    }));
    if (array.length === 0) {
      return [];
    }
    setBetTab(array[0].id);
    return array;
  }, [availableSports]);
  if (!availableSports || availableSports === null || (typeof availableSports === "object" && Object.keys(availableSports).length === 0)) {
    return null;
  }
  return (
    <div className={`${className} grid grid-cols-12`}>
      <div className="col-span-12 bg-white md:bg-custom">
        <div className="shadow-topCombisShadow">
          <div className="flex items-center justify-between w-full px-4 md:px-2 py-3">
            <span className="text-lg md:text-sm font-semibold">{title}</span>
            <div className="flex items-center text-sm md:hidden text-gray-800">
              {allEventsLink && (
                <Link to={allEventsLink} className="ml-1 flex items-center">
                  <span className="text-gray-800 font-semibold">Alle Events</span>
                  <FaChevronRight className="text-gray-400 text-sm" />
                </Link>
              )}
            </div>
          </div>

          <div className="flex bg-white items-center shadow-topCombisShadow w-full h-12 overflow-x-auto gap-1.5 md:h-12 md:flex">
            {sportsArray.map((entries, index) => (
              <div
                key={index}
                className={`flex items-center cursor-pointer px-5 h-full border-b-2  ${
                  betTab === entries.id ? "border-red-700 text-gray-900" : "border-gray-50 text-gray-600"
                } text-center`}
                onClick={async () => {
                  setBetTab(entries.id);
                }}
              >
                <span className="font-semibold truncate font-Source-Code-Pro text-sm">{entries.name}</span>
              </div>
            ))}
          </div>

          <div className="grid">
            <SelectOddNameMatches type={type} betTab={betTab} showDetail={showDetail} isLive={isLive} />
          </div>
          <div className="flex items-center text-sm text-gray-800 justify-end">
            {allEventsLink && (
              <Link to={allEventsLink} className="ml-1 h-10 md:flex hidden items-center">
                <span className="text-gray-800 font-semibold">All Events</span>
                <FaChevronRight className="text-gray-400 text-sm" />
              </Link>
            )}
          </div>
        </div>

        {showDetail ? (
          <div className={`${showDetail ? "col-span-3 flex justify-center" : ""} bg-custom overflow-auto scrollbar-hide`}>
            <div
              className={`fixed mt-11 h-[100vh] z-0 col-md-5 transition-transform duration-1000 ease-in-out transform ${
                showDetail ? "translate-x-1" : "translate-x-12"
              }`}
            ></div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const MobileGenericMainPageBody = ({ type, showDetail, className, title, isLive, allEventsLink }) => {
  const [betTab, setBetTab] = useState();
  const [showSwitch, setShowSwitch] = useState(false);
  const availableSports = useSelector((state) => state?.[type]?.availableSports);

  const sportsArray = useMemo(() => {
    if (!availableSports) {
      return [];
    }
    const array = Object.entries(availableSports).map(([id, sport]) => ({
      id,
      ...sport,
    }));
    if (array.length === 0) {
      return [];
    }
    setBetTab(array[0].id);
    return array;
  }, [availableSports]);
  if (!availableSports || availableSports === null || (typeof availableSports === "object" && Object.keys(availableSports).length === 0)) {
    return null;
  }
  return (
    <div className={`${className} grid grid-cols-12`}>
      <div className="flex items-center w-full overflow-x-auto col-span-12 bg-white md:hidden">
        <div
          className="flex-shrink-0 cursor-pointer bg-white border-r flex flex-col items-center justify-center w-2/12 h-16"
          onClick={() => setShowSwitch(!showSwitch)}
        >
          <PiSlidersHorizontalLight className="text-3xl font-bold" />
        </div>
        {showSwitch && (
          <div>
            <CustomSwitch />
          </div>
        )}
        {sportsArray.map((entries) => {
          return (
            <div
              key={entries.id}
              onClick={() => {
                setBetTab(entries.id);
              }}
              className={`flex-shrink-0 cursor-pointer flex flex-col py-2 bg-white justify-between items-center w-2.5/12 h-16 ${
                betTab === entries.id ? "border-b-2 border-b-red-700" : "bg-livePageTop"
              }`}
            >
              <div className="flex  gap-1">
                <img
                  style={{ textAlign: "center", marginLeft: "14px" }}
                  src={imagesUrl + entries.name.toLowerCase().replace(" ", "-") + ".png"}
                  width={30}
                  height={30}
                />
                <span style={{ fontSize: "11px" }} className="text-xs text-gray-500 font-extralight">
                  {entries.length}
                </span>
              </div>
              <span style={{ fontSize: "11px" }} className="flex w-full text-xs text-gray-500 font-semibold justify-center">
                {entries.name}
              </span>
            </div>
          );
        })}
      </div>
      <div className="col-span-12  bg-white md:bg-custom md:mt-10">
        <div className="shadow-topCombisShadow">
          <div className="flex items-center justify-between w-full  px-4 md:px-2">
            <span className="text-lg md:text-sm font-semibold">{title}</span>
            <div className="flex items-center text-sm md:hidden text-gray-800">
              {allEventsLink && (
                <Link to={allEventsLink} className="ml-1 flex items-center">
                  <span className="text-gray-800 font-semibold">Alle Events</span>
                  <FaChevronRight className="text-gray-400 text-sm" />
                </Link>
              )}
            </div>
          </div>

          <div className="flex bg-white items-center shadow-topCombisShadow w-full h-12 overflow-x-auto gap-1.5 md:h-12 md:flex hidden">
            {sportsArray.map((entries, index) => (
              <div
                key={index}
                className={`flex items-center cursor-pointer px-5 h-full border-b-2 ${
                  betTab === entries.id ? "border-red-700 text-gray-900" : "border-gray-50 text-gray-600"
                } text-center`}
                onClick={async () => {
                  setBetTab(entries.id);
                }}
              >
                <span className="font-semibold truncate font-Source-Code-Pro text-sm">{entries.name}</span>
              </div>
            ))}
          </div>

          <div className="grid">
            <SelectOddNameMatches type={type} betTab={betTab} showDetail={showDetail} isLive={isLive} showCombo={true} />
          </div>
          <div className="flex items-center text-sm text-gray-800 justify-end">
            {allEventsLink && (
              <Link to={allEventsLink} className="ml-1 h-10 md:flex hidden items-center">
                <span className="text-gray-800 font-semibold">All Events</span>
                <FaChevronRight className="text-gray-400 text-sm" />
              </Link>
            )}
          </div>
        </div>

        {showDetail ? (
          <div className={`${showDetail ? "col-span-3 flex justify-center" : ""} bg-custom overflow-auto scrollbar-hide`}>
            <div
              className={`fixed mt-11 h-[100vh] col-md-5 transition-transform duration-1000 ease-in-out transform ${
                showDetail ? "translate-x-1" : "translate-x-12"
              }`}
            ></div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const SelectOddNameMatches = ({ type, showDetail, betTab, isLive, showCombo }) => {
  const [selectedOddsIds, setSelectedOddsIds] = useState([]);

  const handleComboboxValuesChange = useCallback(
    (values) => {
      const filteredValues = values.filter((value) => value !== null && value !== "");
      setSelectedOddsIds(filteredValues);
    },
    [setSelectedOddsIds]
  );

  return (
    <div className="grid">
      <div className={`col-md-7 transition-transform duration-1000 w-full ease-in-out transform ${showDetail ? "-translate-x-0" : "translate-x-0"}`}>
        <div className=" shadow-xl  overflow-visible scrollbar-hide relative">
          <div className={`${showCombo ? "" : "hidden"} sticky top-0 z-20 flex justify-end bg-customs gap-3 sm:flex`}>
            <div className=" flex gap-4 z-20 w-full">
              <GenericComboboxGroup type={type} onValuesChange={handleComboboxValuesChange} showDetail={showDetail} betTab={betTab} />
            </div>
          </div>

          <div className="bg-white w-full">
            <div className="grid grid-cols-12">
              <div className="col-span-4 bg-customs"></div>

              <OddNameMatches type={type} betTab={betTab} selectedOddsIds={selectedOddsIds} isLive={isLive} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const OddNameMatches = ({ type, betTab, selectedOddsIds, isLive }) => {
  const matches = useSelector((state) => state?.[type]?.matches);
  const tournamentMatchMap = useSelector((state) => state?.[type]?.tournamentMatchMap);
  const availableTournamentsMapBySport = useSelector((state) => state?.[type]?.availableTournamentsMapBySport);

  const filterMatches = (tournamentId) => {
    const tournamentMatches = tournamentMatchMap[tournamentId];
    if (!tournamentMatches) {
      console.log(`No matches found for tournamentId: ${tournamentId}`);
      return [];
    }

    const filteredMatches = matches?.filter((match) => tournamentMatches.includes(match.id));
    return filteredMatches || [];
  };

  if (!matches) {
    return <div>No matches found.</div>;
  }

  if (!betTab || availableTournamentsMapBySport === undefined || availableTournamentsMapBySport[betTab] === undefined) {
    return <MatchSkeleton />;
  } else if (availableTournamentsMapBySport[betTab] === null) {
    return <div>Not Tournament Found!</div>;
  }
  return availableTournamentsMapBySport[betTab].map((item) => (
    <Matches
      key={item.id}
      type={type}
      name={item.name}
      country_id={item.country_id}
      matches={filterMatches(item.id)}
      selectedOddsIds={selectedOddsIds}
      isLive={isLive}
    />
  ));
};

const Matches = ({ type, selectedOddsIds, name, country_id, matches, isLive }) => {
  return (
    <div className={`grid-rows-${matches.length} col-span-12 md:overflow-visible md:scrollbar-hide`}>
      <div className=" hidden  md:block md:sticky md:top-9 md:z-10">
        <GenericOddNamesGroup className={`px-6 pl-4`} type={type} selectedOddsIds={selectedOddsIds} tournamentName={`${country_id} / ${name}`} />
      </div>
      {matches.map((match) => {
        return (
          <div key={match.id} className="row-span-1 grid grid-rows-1 col-span-12 h-24 md:h-14">
            <GenericMatchesRow
              key={match.id}
              home_team_name={match.home_team_name}
              home_team_logo={match.home_team_logo}
              away_team_name={match.away_team_name}
              away_team_logo={match.away_team_logo}
              isLive={isLive}
              matchId={match.id}
              periodId={match.period}
              selectedOddsIds={selectedOddsIds}
              sportId={match.sport_id}
              status={match.status_id}
              type={type}
              matchTime={match.match_time}
            />
          </div>
        );
      })}
    </div>
  );
};
