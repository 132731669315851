import { Option, Select } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "../../components/Button/Button";
import { sortingOddsMap2 } from "../utility/AvailableOddsMapSorting";

export const GenericComboboxGroup = ({ type, onValuesChange, showDetail, betTab }) => {
  const [dropDownCount, setDropDownCount] = useState(5);
  const [selectedValues, setSelectedValues] = useState([]);
  const maxDropDownCount = useSelector((state) => state?.[type]?.dropDownCount);
  const availableOddsMap = useSelector((state) => state?.[type]?.availableOddsMap);
  const updateDropDownCount = () => {
    const width = window.innerWidth;
    let count;

    if (width < 650) {
      count = 1;
    } else if (width < 1000) {
      count = 2;
    } else if (width < 1250) {
      count = 3;
    } else if (width < 1500) {
      count = 4;
    } else {
      count = maxDropDownCount && maxDropDownCount === 0 ? 5 : maxDropDownCount;
    }
    if (showDetail) {
      count -= 3;
    }
    setDropDownCount(count);
  };

  useEffect(() => {
    updateDropDownCount();
    window.addEventListener("resize", updateDropDownCount);
    return () => {
      window.removeEventListener("resize", updateDropDownCount);
    };
  }, [maxDropDownCount, showDetail]);

  const handleSelectChange = (index, selectedValue) => {
    const newValues = [...selectedValues];
    newValues[index] = selectedValue;
    setSelectedValues(newValues);
  };

  const filteredAvailableOddsMap = Object.values(availableOddsMap)
    .sort((a, b) => {
      return a._mappedIndex - b._mappedIndex;
    })
    .filter((e) => {
      return e.sport_ids.some((k) => Number(k) === Number(betTab));
    });

  const options = filteredAvailableOddsMap.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  useEffect(() => {
    const initialSelectedValues =
      options.length > 0 ? options.slice(0, dropDownCount).map((opt) => opt.value) : Array(dropDownCount).fill("");
    if (JSON.stringify(initialSelectedValues) !== JSON.stringify(selectedValues)) {
      setSelectedValues(initialSelectedValues);
    }
    if (options.length > 0 && selectedValues.length === 0 && window.innerWidth < 768) {
      setSelectedValues([options[0].value]);
    }
  }, [availableOddsMap, dropDownCount, betTab]);

  useEffect(() => {
    if (onValuesChange) {
      onValuesChange(selectedValues);
    }
  }, [selectedValues]);

  const renderOptions = (selectedValues, currentSelectValue) => {
    return options.map((option) => (
      <Option
        key={option.value}
        value={option.value}
        disabled={option.value !== "" && selectedValues.includes(option.value) && option.value !== currentSelectValue}
      >
        {option.label}
      </Option>
    ));
  };

  return (
    <>
      <div className="grid-cols-12 hidden md:grid gap-3 w-full">
        <div className={`col-span-4 justify-start md:flex hidden space-x-3 ml-4 items-center`} />
        <div className={`col-span-8 grid grid-cols-12`}>
          <div className={`col-span-11 grid-cols-${selectedValues.length} hidden md:grid gap-2`}>
            {selectedValues.map((value, index) => (
              <div key={index} className="grid col-span-1">
                <Select
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: "700",
                    height: "28px",
                    borderRadius: "4px",
                    justifySelf: "left",
                    textAlign: "left",
                  }}
                  className={`${
                    availableOddsMap && availableOddsMap[value] && availableOddsMap[value].isHaveLine ? " bg-black" : ""
                  } bg-black h-7 w-full shadow-none  hover:border-black focus:outline-none pr-6`}
                  placeholder="Select"
                  value={value}
                  onChange={(e, newValue) => handleSelectChange(index, newValue)}
                >
                  {renderOptions(selectedValues, value)}
                </Select>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex block bg-custom md:hidden w-screen px-4 space-x-1 mt-2 overflow-x-scroll whitespace-nowrap pb-2">
        {options.map((value, index) => (
          <div key={index} className="relative flex items-center">
            <Button
              onClick={() => setSelectedValues([value.value])}
              className={`${
                selectedValues.includes(value.value) ? "bg-detailText text-white" : "bg-featuresBg text-gray-700"
              } rounded w-full min-w-20 border border-gray-300 focus:bg-detailText focus:text-white`}
            >
              <span className="text-xs ">{value.label}</span>
            </Button>
          </div>
        ))}
      </div>
    </>
  );
};
