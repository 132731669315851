import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { initializeWebSocketTopMatches, TopMatchesApi } from "../../store/actions/top_matches";
import { GenericMainPageBody } from "../Generic/GenericBody";

export const Top_Events = ({ showDetail }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      TopMatchesApi({
        pageNumber: 1,
        pageSize: 15,
      })
    );
    const socket = dispatch(initializeWebSocketTopMatches());
    return () => {
      if (socket && socket.close) {
        socket.close();
      }
    };
  }, [dispatch]);

  return (
    <GenericMainPageBody
      allEventsLink={"/top-events"}
      type={"topmatches"}
      showDetail={showDetail}
      title={"Top-Events"}
    />
  );
};
