import { CALCULATE_ODD, CLEAR_ODD, REMOVE_ODD, SHOW_DETAIL, SHOW_DETAIL_HOME, SHOW_DETAIL_LIVE } from "./actiontypes";

const showDetailHome = (data) => {
  return {
    type: SHOW_DETAIL_HOME,
    payload: {
      showDetail: data,
    },
  };
};

const showDetailLive = (data) => {
  return {
    type: SHOW_DETAIL_LIVE,
    payload: {
      showDetail: data,
    },
  };
};

const showOddDetail = () => {
  return {
    type: SHOW_DETAIL,
  };
};

const calculateOdds = (payload) => {
  return {
    type: CALCULATE_ODD,
    payload: {
      id: payload.id,
      matchId: payload.matchId,
      home_team_name: payload.home_team_name,
      away_team_name: payload.away_team_name,
      oddsId: payload.oddsId,
      option: payload.option,
      line: payload.line,
      value: payload.value,
      market_name: payload.market_name,
    },
  };
};

const removeOdd = (bet) => {
  return {
    type: REMOVE_ODD,
    id: bet.id,
    oddValue: bet.oddValue,
  };
};

const clearOdds = () => {
  return {
    type: CLEAR_ODD,
  };
};

const updateRequestBody = (requestBody) => {
  return {
    type: "UPDATEREQUESTBODY",
    payload: requestBody,
  };
};

const customSwitch = () => {
  return {
    type: "CUSTOMSWITCHCHANGE",
  };
};

export {
  showOddDetail,
  calculateOdds,
  removeOdd,
  clearOdds,
  showDetailHome,
  showDetailLive,
  updateRequestBody,
  customSwitch,
};
