import React from "react";
import { MainPage } from "../../../components/LandingPage/main_page";
import { TopEvents } from "../../../components/LandingPage/Top_Evetns";
import { TopCombis } from "../../../components/LandingPage/Top_Combis";
import { TopGames } from "../../../components/LandingPage/Today_Games";

import { useSelector } from "react-redux";
import { FilteredHome } from "../../../components/LandingPage/Filtered_Football";
import { PopupMatchCombi } from "../../../components/Dialog/PopupMatchCombi";
import { GenericDetailCard } from "../../../components/Generic/GenericDetailCard";

export default function HomePage() {
  const activeMenu = useSelector((state) => state.sidebar.selectedItem);
  const showDetail = useSelector((state) => state?.homereducer?.showDetail);

  const requestBody = useSelector((state) => state?.homereducer?.requestBody);
  return (
    <>
      <div className="flex w-full h-[100vh] bg-custom">
        <div className={`w-full h-[95vh] sm:pl-5 bg-custom overflow-auto scrollbar-hide`}>
          {/* Render content based on activeMenu */}
          {(() => {
            switch (activeMenu) {
              case undefined:
              case null:
                return <MainPage showDetail={showDetail} />;
              case "top-events":
                return <TopEvents showDetail={showDetail} />;
              case "top-combis":
                return <TopCombis showDetail={showDetail} />;
              case "todays-matches":
                return (
                  <FilteredHome
                    showDetail={showDetail}
                    body={{
                      pageNumber: 1,
                      pageSize: 300,
                      hour: 12,
                    }}
                  />
                );
              case "german-football":
                return (
                  <FilteredHome
                    showDetail={showDetail}
                    body={{
                      pageNumber: 1,
                      pageSize: 100,
                      tournament_ids: [1268, 1773],
                    }}
                  />
                );
              case "european-football":
                return (
                  <FilteredHome
                    showDetail={showDetail}
                    header={"European Football"}
                    body={{
                      pageNumber: 1,
                      pageSize: 100,
                      tournament_ids: [8923],
                    }}
                  />
                );
              default:
                return (
                  <FilteredHome
                    showDetail={showDetail}
                    body={{
                      pageNumber: 1,
                      pageSize: 100 * requestBody.length,
                      tournament_ids: requestBody.map((e) => e.tournamentId),
                    }}
                  />
                );
            }
          })()}
        </div>

        {showDetail && (
          <div className="bg-custom sticky top-6 md:h-[20vh]  col-span-4">
            <GenericDetailCard />
          </div>
        )}
        <PopupMatchCombi />
      </div>
    </>
  );
}
