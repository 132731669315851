import { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { goal } from "../../assets/images";
import { removeMatches } from "../../store/actions/live_matches";
import moment from "moment";

export const LiveGenericMinutes = memo(({ match_Id, status, sport_id, periodId }) => {
  const dispatch = useDispatch();
  const matchId = Number(match_Id);
  const time = useSelector((state) => state?.livematches?.matchesTimer?.[matchId]);
  const goals = useSelector((state) => state?.livematches?.goals?.[matchId]);
  const [changeScore, setChangeScore] = useState(false);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!goals) {
      return;
    }
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (sport_id !== 1) {
      return;
    }

    setChangeScore(true);
    const timeoutId = setTimeout(() => {
      setChangeScore(false);
    }, 20000);
    return () => clearTimeout(timeoutId);
  }, [goals]);

  useEffect(() => {
    if (periodId === 100) {
      const timeoutId = setTimeout(() => {
        dispatch(removeMatches(matchId));
      }, 15000);
      return () => clearTimeout(timeoutId);
    }
  }, [periodId]);

  if (changeScore) {
    return <Goal />;
  }
  if (Number(status) > 3) {
    return <End />;
  }
  return <LiveTime time={time} />;
});

export const GenericMinutes = memo(({ matchTime }) => (
  <div className={`col-span-2 flex place-content-center flex-col w-20  text-gray-600 overflow-hidden text-[11px]`}>
    <span className="w-full mb-1 whitespace-nowrap truncate">{moment(matchTime * 1000).format("dddd")}</span>
    <span className="whitespace-nowrap truncate">{moment(matchTime * 1000).format("hh:mm")}</span>
  </div>
));

const Goal = () => (
  <div className="col-span-1 grid place-content-center grid-cols-1 text-xs text-gray-500 truncate">
    <img src={goal} alt="goal"></img>
  </div>
);

const End = () => (
  <div className="flex">
    <span className="font-bold place-content-center text-sm md:text-md">END</span>
  </div>
);

const LiveText = () => (
  <div className="hidden md:block">
    <span className="font-bold mt-2  text-xxs text-red-700">LIVE</span>
  </div>
);

const LiveTime = ({ time }) => (
  <div className="flex flex-col">
    <span
      className={`font-bold text-xs w-24 mt-4 md:text-sm md:text-md md:mt-2 place-content-center md:font-semibold md:text-popupText text-red-600 md:text-gray-800`}
    >
      {time}
    </span>
    <LiveText />
  </div>
);
