export const Login_URL = `${process.env.REACT_APP_API_URL2}/api/user/login`;
export const Registration_URL = `${process.env.REACT_APP_API_URL2}/api/user/register`;
export const MatchData_URL = `${process.env.REACT_APP_API_URL2}/api/match/get-match-data`;
export const sports_Api = `${process.env.REACT_APP_API_URL2}/api/mapping/sports`;
export const sports_length_Api = `${process.env.REACT_APP_API_URL2}/api/match/get-total-counts`;
export const top_matches = `${process.env.REACT_APP_API_URL2}/api/match//top-matches`;
export const placeBetSlip = `${process.env.REACT_APP_API_URL2}/api/betslip/place-betslip/`;
export const calculateBetSlip = `${process.env.REACT_APP_API_URL2}/api/betslip/calculate-betslip/`;
export const filtered_home = `${process.env.REACT_APP_API_URL2}/api/match/get-match-data`;
export const last_min = `${process.env.REACT_APP_API_URL2}/api/match/last-minute`;
export const top_winner = `${process.env.REACT_APP_API_URL2}/api/top-winner/get-winner-list`;
export const lives_matches = `${process.env.REACT_APP_API_URL2}/api/match/live`;
export const balance_Url = `${process.env.REACT_APP_API_URL2}/api/user/get-balance`;
export const transactionOptionsUrl = `${process.env.REACT_APP_API_URL2}/api/transaction/get-transactions-date-options`;
export const userTransactionUrl = `${process.env.REACT_APP_API_URL2}/api/transaction/user-transactions`;
export const getCouponUrl = `${process.env.REACT_APP_API_URL2}/api/user/get-coupons`;
export const cashoutUrl = `${process.env.REACT_APP_API_URL2}/api/combi/cashout-coupon`;
export const topCombisUrl = `${process.env.REACT_APP_API_URL2}/api/combi/get-top-combis`;

export const top_competition = `${process.env.REACT_APP_API_URL3}/api/menu/top-tournaments`;
export const webSocketUrl = `${process.env.REACT_APP_API_URL3}`;
export const navigationTreeUrl = `${process.env.REACT_APP_API_URL3}/api/menu/navigation-tree`;
export const imagesUrl = `${process.env.REACT_APP_API_URL}/images/sports/`;
