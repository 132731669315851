// src/app/pages/auth/Login.js
import React, { useEffect, useState } from "react";
import registerImage from "../../../assets/images/register.jpeg";
import SimpleInput from "../../../components/Input/Input";
import { Button } from "../../../components/Button/Button";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../store/actions/authActions";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Stack } from "@mui/material";
import { loginValidationSchema } from "../../schema/login";
import { mybets } from "../../../assets/images";
const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const error = useSelector((state) => state.auth.error);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated]);

  const formHandler = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: loginValidationSchema,

    onSubmit: async (values) => {
      setloading(true);
      await dispatch(login(values));

      setloading(false);
      // Navigate only if login is successful
      if (isAuthenticated) {
        navigate("/");
      }
    },
  });

  return (
    <div className="w-full h-screen flex flex-col   bg-gray-100 ">
      <div className="w-full flex flex-col justify-center items-center mt-40">
        <div className="mb-5">
          <img src={mybets} alt="" />
        </div>
        <div className="flex flex-col text-center mb-9">
          <span className="font-bold">Don't miss your bets</span>
          <span>Login to trak your placed bets</span>
        </div>
        <div>
          <Link
            to="/login"
            className="bg-[#4D90A7] text-white text-sm font-medium px-12 py-3 rounded shadow-md hover:bg-[#3b7d8e] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#4D90A7] transition"
            >
            Login
          </Link>
        </div>
      </div>
      
    </div>
  );
};

export default LoginPage;
