import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customSwitch } from "../../store/actions/homeTypes";

const CustomSwitch = () => {
  const dispatch = useDispatch();
  const selectionMode = useSelector((state) => state?.homereducer?.customSwitch);
  const [isModalOpen, setIsModalOpen] = useState(false); // Başlangıçta kapalı
  const [selectedOption, setSelectedOption] = useState("Zeit");

  useEffect(() => {
    setIsModalOpen(true); // Bileşen yüklendiğinde modal açılır
  }, []);

  const updateSelection = (option) => {
    setSelectedOption(option); // Seçili opsiyonu güncelle
  };

  const confirmSelection = () => {
    dispatch(customSwitch(selectedOption)); // Seçilen opsiyonu onayla ve modalı kapat
    setIsModalOpen(false);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <div className="flex justify-center items-center hidden md:flex">
        <div className={`flex w-32 h-7 bg-customs border border-gray-300 rounded-full`}>
          <button
            onClick={() => dispatch(customSwitch())}
            className={`flex-1 flex text-xs font-medium justify-center items-center ${
              selectionMode ? `rounded-full bg-white border border-gray-100 shadow-md` : ""
            } `}
          >
            {"Time"}
          </button>
          <button
            onClick={() => dispatch(customSwitch())}
            className={`flex-1 flex text-xs font-medium justify-center items-center ${
              !selectionMode ? `rounded-full bg-white border border-gray-100 shadow-md` : ""
            } `}
          >
            {"Contest"}
          </button>
        </div>
      </div>

      {/* Modal görünümü */}
      {isModalOpen && (
        <div className="flex justify-center items-center md:hidden w-screen">
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-end">
            <div
              className={`bg-white rounded-t-lg w-full max-w-md transition-opacity transition-transform duration-300 ease-out 
              ${isModalOpen ? "opacity-100 translate-y-1" : "opacity-0 translate-y-4"}`}
            >
              <div className="flex justify-between items-center  border-b py-2 px-3">
                <div></div>
                <div className=" font-semibold">Sort by</div>
                <button onClick={toggleModal} className="text-gray-500 text-3xl">
                  &times;
                </button>
              </div>
              <div className="pb-3 px-3">
                <ul className="">
                  {["Zeit", "Wettbewerb", "Odds: Low to high", "Odds: High to low"].map((option) => (
                    <li className="" key={option}>
                      <button
                        onClick={() => updateSelection(option)}
                        className="flex items-center w-full  text-left border-b rounded-md py-3"
                      >
                        <span className="flex-grow">{option}</span>
                        {selectedOption === option ? (
                          <div className="flex items-center justify-center">
                            <div className="w-6 h-6 rounded-full border-2 border-green-400 flex items-center justify-center">
                              <div className="w-3 h-3 rounded-full bg-green-500"></div>
                            </div>
                          </div>
                        ) : (
                          <div className="flex items-center justify-center">
                            <div className="w-6 h-6 rounded-full border-2 border-green-400 flex items-center justify-center"></div>
                          </div>
                        )}
                      </button>
                    </li>
                  ))}
                </ul>

                <button
                  onClick={confirmSelection}
                  className=" w-full bg-transparent text-blue-500 rounded p-2 border border-blue-500 hover:bg-blue-500 hover:text-white transition mt-3"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomSwitch;
