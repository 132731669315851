import React, { useState, useEffect } from "react";
import { IoFilterOutline } from "react-icons/io5";
import { TransactionSvg2 } from "./icon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { transactionOptionsUrl, userTransactionUrl } from "../../../store/paths";

export const Transaktionsverlauf = () => {
  const [selectedMonth, setSelectedMonth] = useState();
  const [transactionData, setTransactionData] = useState(null);
  const [months1, setMonths1] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const storedUserData = JSON.parse(localStorage.getItem("authToken"));
        const token = storedUserData?.user?.token;
        if (!token) throw new Error("Token not found in localStorage");

        const response = await fetch(transactionOptionsUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) throw new Error("Network response was not ok");

        const data = await response.json();
        setMonths1(data);
        setSelectedMonth(data[0].monthName);

        // Sayfa açıldığında seçili ayın verilerini hemen çek
        fetchBets(data[0].startDate, data[0].endDate);
      } catch (error) {
        console.error("Veri çekilirken hata oluştu:", error.message);
        setMonths1(null);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const fetchBets = async (startDate, endDate) => {
    setLoading(true);
    try {
      const storedUserData = JSON.parse(localStorage.getItem("authToken"));
      const token = storedUserData?.user?.token;
      if (!token) throw new Error("Token not found in localStorage");

      const response = await fetch(userTransactionUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ startDate, endDate }),
      });

      if (!response.ok) throw new Error("Network response was not ok");

      const data = await response.json();
      const [firstElement] = months1.filter((e) => e.monthName === selectedMonth);

      setTransactionData({
        dateRange: `${firstElement}`,
        transactions: data,
      });
    } catch (error) {
      console.error("Veri çekilirken hata oluştu:", error.message);
      setTransactionData(null);
    } finally {
      setLoading(false);
    }
  };

  const handleFilter = () => {
    const start = startDate.toISOString();
    const end = endDate.toISOString();
    fetchBets(start, end);
    setShowFilterPopup(false);
  };

  const handleMonthChange = (monthName) => {
    setSelectedMonth(monthName);
    const [firstElement] = months1.filter((e) => e.monthName === monthName);
    fetchBets(firstElement.startDate, firstElement.endDate);
  };

  return (
    <div className="flex justify-center w-full min-h-screen bg-gray-100 p-4">
      <div className="w-full max-w-lg md:max-w-2xl lg:max-w-4xl p-4">
        <h2 className="w-full text-lg font-semibold text-gray-700 mb-4 text-left hidden md:block">Transaktionsverlauf</h2>
        <div className="w-full ">
          <div className="flex overflow-x-auto md:overflow-x-hidden border-b space-x-1 border-gray-300 pb-2 text-xs md:text-sm lg:text-base font-semibold mb-4">
            {months1.map((month) => (
              <button
                key={month.monthName}
                onClick={() => handleMonthChange(month.monthName)}
                className={`px-2 py-1 font-medium whitespace-nowrap ${selectedMonth === month.monthName ? "border-b-2 border-red-500" : "text-gray-600"}`}
              >
                {month.monthName}
              </button>
            ))}
          </div>

          <div className="flex justify-between items-center mb-4 bg-white p-3 md:p-4 lg:p-6 rounded-lg shadow-sm">
            <span className="text-gray-600 text-sm md:text-base lg:text-lg">
              {loading ? "Yükleniyor..." : transactionData && transactionData.transactions ? `${transactionData.transactions.length} İşlemler` : "0 İşlemler"}
            </span>
            <button className="flex items-center text-gray-600 text-sm md:text-base lg:text-lg" onClick={() => setShowFilterPopup(true)}>
              <IoFilterOutline />
              Filter
            </button>
          </div>

          <h3 className="text-md md:text-lg lg:text-xl text-gray-700 font-semibold mb-4">Hesap aktivite özeti</h3>
          <div className="bg-white p-3 md:p-4 lg:p-6 rounded-lg shadow-sm mb-4">
            {loading ? (
              <p>Yükleniyor...</p>
            ) : transactionData && transactionData.transactions.length > 0 ? (
              <>
                <p className="text-gray-600 mb-2 text-sm md:text-base font-semibold lg:text-lg">
                  {/* {transactionData.dateRange} */}
                  Transactions
                </p>
                <div className="space-y-2">
                  {transactionData.transactions.map((transaction) => (
                    <div key={transaction.id} className="flex justify-between items-center py-1 md:py-2">
                      <span className="w-1/4 text-xs md:text-sm lg:text-base font-semibold">{transaction.transaction_type}</span>
                      <span className="w-1/2 text-center text-xs md:text-sm lg:text-base">{transaction.description}</span>
                      <span className="w-1/4 text-right font-medium text-sm md:text-base lg:text-lg">€{transaction.amount}</span>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <p>Bu ay için veri yok.</p>
            )}
          </div>

          {!loading && !transactionData && (
            <div className="bg-yellow-100 p-3 md:p-4 lg:p-6 rounded-lg shadow-sm flex items-center text-yellow-800 text-sm md:text-base lg:text-lg mb-4">
              <TransactionSvg2 />
              Bu ay içinde yapılan işlem yok.
            </div>
          )}
        </div>
      </div>

      {showFilterPopup && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96 relative">
            <button onClick={() => setShowFilterPopup(false)} className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
              &times;
            </button>
            <h2 className="text-lg font-semibold mb-4 text-center">Filtre</h2>

            <div className="flex space-x-4 mb-4">
              <div className="w-1/2">
                <label className="block text-sm font-medium mb-2">Başlangıç tarihi</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="dd.MM.yyyy"
                  className="w-full border rounded p-2 text-center"
                  placeholderText="Başlangıç tarihi"
                />
              </div>
              <div className="w-1/2">
                <label className="block text-sm font-medium mb-2">Bitiş tarihi</label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="dd.MM.yyyy"
                  className="w-full border rounded p-2 text-center"
                  placeholderText="Bitiş tarihi"
                />
              </div>
            </div>

            <button onClick={handleFilter} className="w-full bg-teal-500 text-white py-2 rounded mt-2 hover:bg-teal-600">
              Rezervasyonları göster
            </button>
            <div className="text-center mt-4">
              <a href="#" className="text-teal-500 underline text-sm">
                CSV dosyasını indirin (1 yıl)
              </a>
              <p className="text-gray-400 text-xs">Dün dahil son 12 aya kadar olan işlemler.</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
