import React from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";

const SelectComponent = ({ control, name, options, isMulti, label, error }) => {
  return (
    <div className="mb-4">
      <label className="block text-gray-700 text-sm font-semibold mb-2">{label}</label>

      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, ref } }) => (
          <Select
            value={options.filter((option) => value?.includes(option.value))}
            onChange={(selectedOption) => {
              if (isMulti) {
                const values = selectedOption ? selectedOption.map((option) => option.value) : [];
                onChange(values);
              } else {
                onChange(selectedOption ? selectedOption.value : "");
              }
            }}
            options={options}
            isMulti={isMulti}
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: "#f3f4f6",
                height: "47px",
                borderRadius: "6px",
                borderColor: "#757575",
                color: "#757575",
                boxShadow: "none",
                "&:hover": {
                  borderColor: "#757575",
                },
              }),
              indicatorSeparator: () => ({ display: "none" }),
            }}
          />
        )}
      />

      {error && <div className="text-red-500 mt-2 text-sm">{error}</div>}
    </div>
  );
};

export default SelectComponent;
