import { useDispatch, useSelector } from "react-redux";
import { calculateOdds } from "../../store/actions/homeTypes";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { shirt1 } from "../../assets/images";
import "../../app/pages/user-page/userPage.css";
import { leftarrow, rightarrow } from "../../assets/images";
import { topCombisUrl } from "../../store/paths";
import CustomSwitch from "../CustomSwitch";
import { PiSlidersHorizontalLight } from "react-icons/pi";
import { isEqual } from "lodash";
import { imagesUrl } from "../../store/paths";

export function TopCombis({ showDetail, header }) {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataLen, SetDataLeng] = useState(0);
  const [showSwitch, setShowSwitch] = useState(false);
  const counts = useSelector((state) => state?.apisport?.counts);
  const [betTab, setBetTab] = useState();
  const openPopup = (matchDetails) => {
    document.body.classList.add("no-scroll");
    SetDataLeng(Object.values(data.combis).length);
    console.log("selected index: ", selectedIndex);
    console.log("data len: ", dataLen);
    setSelectedMatch(matchDetails);
    setIsPopupVisible(true);
  };

  const closePopup = () => {
    document.body.classList.remove("no-scroll");
    setIsPopupVisible(false);
    setSelectedMatch(null);
  };
  const nextCombis = () => {
    var match = Object.values(data.combis)[selectedIndex + 1];
    setSelectedIndex(selectedIndex + 1);
    setSelectedMatch(match);
  };
  const prevCombis = () => {
    var match = Object.values(data.combis)[selectedIndex - 1];
    setSelectedIndex(selectedIndex - 1);
    setSelectedMatch(match);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(topCombisUrl);
        setData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleOdd = () => {
    data.combiMatchMap[selectedMatch.combiId].forEach((e) => {
      const asd = data.combisMatchData[e];
      dispatch(
        calculateOdds({
          id: asd.matchId + "-" + asd.marketId + "-" + asd.outcomeId,
          matchId: asd.matchId,
          home_team_name: asd.homeName,
          away_team_name: asd.awayName,
          oddsId: asd.outcomeId,
          option: asd.resultName,
          value: asd.Odd,
          line: "",
          market_name: asd.market_name,
        })
      );
    });
    closePopup();
  };

  const sportsArray = useMemo(() => {
    if (!counts || counts.length === 0) {
      return [];
    }
    const array = [...counts.filter((e) => e.length)];
    const totalLength = array.reduce((sum, sport) => Number(sum) + Number(sport.length), 0);
    array.push({ id: 9999, name: "AllBalls", length: totalLength });
    const minBetTab = Math.min(...array.map((e) => e.id));
    if (betTab || !isEqual(betTab, minBetTab)) {
      setBetTab(minBetTab);
    }
    return array;
  }, [counts]);

  if (!data)
    return (
      <div className="shadow rounded-md p-4 max-w-sm w-64 mx-auto">
        <div className="animate-pulse flex space-x-4">
          <div className="rounded-full bg-slate-200 h-10 w-10"></div>
          <div className="flex-1 space-y-6 py-1">
            <div className="h-2 bg-slate-200 rounded"></div>
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                <div className="h-2 bg-slate-200 rounded col-span-1"></div>
              </div>
              <div className="h-2 bg-slate-200 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className="md:pt-8 ">
      
      {/* top */}
      {/* {!inMain && (
        <div className={`flex items-center w-full gap-4  pb-4`}>
          <span>Sort by:</span>
          <CustomSwitch />
        </div>
      )} */}
      {/* Tabs  */}
      <div className={`${false ? "w-1/2" : "w-full"} bg-custom shadow-sm rounded-sm md:my-4`}>
        {/* <div className="flex items-center w-full h-14 shadow-bottom-only overflow-auto scrollbar-hide">
          {Object.values(data.sportCombisMap).map((values, index) => (
            <div
              // key={index}
              // className={`flex items-center cursor-pointer px-3 h-full border-b-2 ${betTab === values?.name
              //     ? "border-b-2 border-red-500"
              //     : "border-b-2 border-gray-50"
              //   } text-center`}
              // onClick={async () => {
              //   setBet(values?.name);
              // }}
            >
              <span className="font-semibold truncate font-Source-Code-Pro text-sm">
                {values?.name}
              </span>
            </div>
          ))}
        </div> */}

        <div className="p-8 hidden bg-white md:block">
          {Object.values(data.combis).map((card, index) => (
            <div key={index} className="w-full flex flex-col my-2 rounded-lg border border-gray-200">
              <div className="flex items-center py-2 px-8 gap-12 border-b border-gray-200">
                <div className="flex flex-col items-center">
                  <div className="flex gap-1">
                    <span className="bg-green-700 rounded-sm font-semibold text-white px-1 flex">{card.betsPlaced}</span>
                    <div>
                      <span className="animate-blink opacity-0 font-extrabold text-green-700">
                        <div className="transform rotate-[315deg]">
                          <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 14 14" width="14">
                            <path d="m0 0h14v14h-14z" fill="none" />
                            <path
                              d="m11 2.05h-7.42c-.55 0-.69.32-.3.71l2.65 2.65-3.76 3.76 2.83 2.83 3.76-3.76 2.53 2.53c.4.4.71.26.71-.3v-7.41a1 1 0 0 0 -1-1.01z"
                              fill="#39c500"
                              fillRule="evenodd"
                            />
                          </svg>
                        </div>
                      </span>
                    </div>
                  </div>
                  <span className="text-sm text-gray-500 font-medium pr-6">Bets placed</span>
                </div>

                <div className="flex flex-col items-center">
                  <span>
                    <b>{data.combiMatchMap[card.combiId].length}x</b>
                  </span>
                  <span className="text-xs text-gray-500 font-medium">Combi</span>
                </div>

                <div className="flex flex-col items-center">
                  <span>
                    <b>{card.totalOdds}</b>
                  </span>
                  <span className="text-xs text-gray-500 font-medium">Total quota</span>
                </div>
              </div>

              {/* Maçları yan yana göstermek için flex-container */}
              <div className="flex h-16 py-1">
                <div className="Markets-styles-module-inline-markets Markets-styles-module-desktop gap-2 flex w-4/5">
                  {data.combiMatchMap[card.combiId].map((matchId, matchIndex) => (
                    <div
                      key={matchIndex}
                      className="CombiCard-styles-module-content PopularCombis-styles-module-combi-card-content w-32 gap-2 flex-shrink-0 mx-1"
                    >
                      <div className="Markets-styles-module-market">
                        <div className="CombiMarketResult-styles-module-market-result flex items-center">
                          <div className="EventTeams-styles-module-logo">
                            <img src={shirt1} width={20} alt="" className="" />
                          </div>
                          <span className="CombiMarketResult-styles-module-market-result-value ">
                            {{
                              1: data.combisMatchData[matchId].homeName,
                              2: data.combisMatchData[matchId].awayName,
                            }[data.combisMatchData[matchId].resultName] || data.combisMatchData[matchId].resultName}
                          </span>
                        </div>
                        <div className="MarketName-styles-module-market-name text-xs font-medium text-gray-400">
                          {data.combisMatchData[matchId].homeName} - {data.combisMatchData[matchId].awayName}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex items-center">
                  <div className="flex justify-center mt-4 mb-3">
                    <button
                      onClick={() => {
                        openPopup(card);
                        setSelectedIndex(index);
                      }}
                      className="bg-combisColor w-12/12  h-11 text-white px-4 py-2 rounded"
                    >
                      See the betting slip #{index + 1}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {isPopupVisible && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
              <div className="bg-white rounded-lg w-96 customs:w-1/4 relative">
                <div className="flex justify-between items-center pb-2 p-4 mb-2">
                  <div>
                    <div className="flex flex-col items-start">
                      <span className="blinking text-white text-sm font-semibold px-1 rounded bg-blue-500">{selectedMatch.betsPlaced}</span>
                      <span className="text-continually text-gray-600 text-left px-0.4 mt-0.5 rounded block ">Bets Placed</span>
                    </div>
                    <div></div>
                  </div>
                  <div className="flex-grow text-center pr-5">
                    <span className="font-semibold">{data.combiMatchMap[selectedMatch.combiId].length}s Combi</span>
                  </div>
                  <button onClick={closePopup} className="text-gray-600 hover:text-black text-2xl" style={{ width: "24px", height: "24px" }}>
                    &#10005; {/* X icon */}
                  </button>
                </div>

                {/* Content Section */}
                <div className="text-center mt-2">
                  {/* <p>Bets Placed: {selectedMatch.betsPlaced}</p> */}
                  {/* <p>Total Odds: {selectedMatch.totalOdds}</p> */}

                  {/* Non-Clickable Table in Popup */}
                  <div className="popup">
                    {data.combiMatchMap[selectedMatch.combiId].map((matchId, index) => {
                      const match = data.combisMatchData[matchId];
                      return (
                        <div key={index} className=" border text-left text-sm">
                          <div className="CombiPopupMarket-styles-module-game bg-combisBg">
                            <div className="CombiPopupMarket-styles-module-title">
                              <div className="CombiMarketResult-styles-module-market-result CombiPopupMarket-styles-module-title-left-part">
                                <span className="CombiMarketResult-styles-module-market-result-label text-popupText">Tipp : {match.resultName} </span>
                              </div>
                              <div className="CombiPopupMarket-styles-module-odds CombiPopupMarket-styles-module-bold">{match.Odd}</div>
                            </div>
                            <div className="text-xs text-gray-500">
                              {match.homeName} - {match.awayName}
                            </div>
                            <div className="text-xs text-gray-500">{match.eventStartTime}</div>
                          </div>
                        </div>
                      );
                    })}
                    <div className="match-container text-left text-sm mb-0" style={{ backgroundColor: "rgb(244, 246, 246)" }}>
                      <div className="CombiPopup-styles-module-total">
                        <span>Total Odds:</span>
                        <div className="CombiPopup-styles-module-odds">{selectedMatch.totalOdds}</div>
                      </div>
                    </div>
                    <div className="flex justify-center mt-4 mb-3">
                      <button
                        onClick={() => {
                          handleOdd();
                        }}
                        className="bg-combisColor w-11/12  h-12 text-white px-4 py-2 rounded"
                      >
                        Add Bet to Coupon
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex items-center w-full bg-white md:hidden">
          <div
            className="flex-shrink-0 cursor-pointer bg-white border-r flex flex-col items-center justify-center w-2/12 h-16"
            onClick={() => setShowSwitch(!showSwitch)}
          >
            <PiSlidersHorizontalLight className="text-3xl font-bold" />
          </div>
          {showSwitch && (
            <div>
              <CustomSwitch />
            </div>
          )}
          {sportsArray
            .filter((entries) => entries.name.toLowerCase() === "football")
            .map((entries) => {
              return (
                <div
                  key={entries.id}
                  onClick={() => {
                    setBetTab(entries.id);
                  }}
                  className={`flex-shrink-0 cursor-pointer flex flex-col py-2 bg-white justify-between items-center w-2/12 h-16 ${betTab === entries.id ? "border-b-2 border-b-red-700" : "bg-livePageTop"
                    }`}
                >
                  <div className="flex  gap-1">
                    <img
                      style={{ textAlign: "center", marginLeft: "14px" }}
                      src={imagesUrl + entries.name.toLowerCase().replace(" ", "-") + ".png"}
                      width={30}
                      height={30}
                    />
                    <span style={{ fontSize: "11px" }} className="text-xs text-gray-500 font-extralight">
                      {Object.values(data.combis).length}
                    </span>
                  </div>
                  <span style={{ fontSize: "11px" }} className="flex w-full text-xs text-gray-500 font-semibold justify-center">
                    {entries.name}
                  </span>
                </div>
              );
            })}
        </div>
        <div className="p-2 md:hidden  block">

          {Object.values(data.combis).map((card, index) => (
            <div key={index} className="w-full flex flex-col my-2 rounded-lg border bg-white border-gray-200">
              <div className="flex items-center py-2 justify-center px-8 gap-12 border-b border-gray-200">
                <div className="flex flex-col items-center">
                  <div className="flex gap-1">
                    <span className="bg-combisColor w-12 rounded-sm font-semibold justify-center text-white px-1 flex">{card.betsPlaced}</span>
                    <div>
                      <span className="animate-blink opacity-0 font-extrabold text-green-700">
                        <div className="transform rotate-[315deg]">
                          <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 14 14" width="14">
                            <path d="m0 0h14v14h-14z" fill="none" />
                            <path
                              d="m11 2.05h-7.42c-.55 0-.69.32-.3.71l2.65 2.65-3.76 3.76 2.83 2.83 3.76-3.76 2.53 2.53c.4.4.71.26.71-.3v-7.41a1 1 0 0 0 -1-1.01z"
                              fill="#39c500"
                              fillRule="evenodd"
                            />
                          </svg>
                        </div>
                      </span>
                    </div>
                  </div>
                  <span className="text-xs text-gray-500 font-medium pr-6">Bets placed</span>
                </div>

                <div className="flex flex-col items-center">
                  <span>
                    <b>{data.combiMatchMap[card.combiId].length}s</b>
                  </span>
                  <span className="text-xs text-gray-500 font-medium">Combi</span>
                </div>

                <div className="flex flex-col items-center">
                  <span>
                    <b>{card.totalOdds}</b>
                  </span>
                  <span className="text-xs text-gray-500 font-medium">Total quota</span>
                </div>
              </div>
              <div className="CombiCard-styles-module-content">
                <div className="w-custom flex flex-col Markets-styles-module-list overflow-y-hidden justify-start items-start h-20 bg-white border-b border-gray-200 overflow-auto">
                  {data.combiMatchMap[card.combiId].map((matchId, idx) => (
                    <div className="flex flex-col px-3 py-1" key={idx}>
                      <div className="flex items-center">
                        <img width={20} src={shirt1} alt="" className="mr-2" />
                        <span className="text-continually font-medium">
                          {{
                            1: data.combisMatchData[matchId].homeName,
                            2: data.combisMatchData[matchId].awayName,
                          }[data.combisMatchData[matchId].resultName] || data.combisMatchData[matchId].resultName}
                        </span>
                      </div>
                      <div className={`${idx === 2 && "opacity-25"} text-xs font-medium text-gray-500`}>
                        {data.combisMatchData[matchId].homeName} - {data.combisMatchData[matchId].awayName}
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  onClick={() => {
                    openPopup(card);
                    setSelectedIndex(index);
                  }}
                  className="bg-combisColor w-full  h-11 text-white px-4 py-2 rounded"
                >
                  See the betting slip #{index + 1}
                </button>
              </div>
            </div>
          ))}
          {isPopupVisible && (
            <div className="fixed inset-0 flex md:items-center md:justify-center bg-black bg-opacity-60 z-50">
              <div className="bg-white rounded-lg w-full md:w-96 customs:w-1/4 md:relative absolute bottom-0">
                <div className="flex justify-between items-center pb-2 p-4 mb-2">
                  <div>
                    <div className="flex flex-col items-start">
                      <span className="blinking text-white text-sm font-semibold px-1 rounded bg-blue-500">{selectedMatch.betsPlaced}</span>
                      <span className="text-black text-continually text-gray-600 text-left px-0.4 mt-0.5 rounded block ">Bets Placed</span>
                    </div>
                    <div></div>
                  </div>
                  <div className="flex-grow text-center pr-5">
                    <span className="font-semibold">{data.combiMatchMap[selectedMatch.combiId].length}s Combi</span>
                  </div>
                  <button onClick={closePopup} className="text-gray-600 hover:text-black text-2xl" style={{ width: "24px", height: "24px" }}>
                    &#10005; {/* X icon */}
                  </button>
                </div>

                {/* Content Section */}
                <div className="text-center mt-2 ">
                  {/* <p>Bets Placed: {selectedMatch.betsPlaced}</p> */}
                  {/* <p>Total Odds: {selectedMatch.totalOdds}</p> */}

                  {/* Non-Clickable Table in Popup */}
                  <div className="popup relative ">
                    <div className="overflow-auto max-h-[70vh]">
                      {data.combiMatchMap[selectedMatch.combiId].map((matchId, index) => {
                        const match = data.combisMatchData[matchId];
                        return (
                          <div key={index} className=" border text-left text-sm">
                            <div className="CombiPopupMarket-styles-module-game bg-combisBg">
                              <div className="CombiPopupMarket-styles-module-title">
                                <div className="CombiMarketResult-styles-module-market-result CombiPopupMarket-styles-module-title-left-part">
                                  <span className="CombiMarketResult-styles-module-market-result-label text-popupText">Tipp : {match.resultName} </span>
                                </div>
                                <div className="CombiPopupMarket-styles-module-odds CombiPopupMarket-styles-module-bold">{match.Odd}</div>
                              </div>
                              <div className="text-xs text-gray-500">
                                {match.homeName} - {match.awayName}
                              </div>
                              <div className="text-xs text-gray-500">{match.eventStartTime}</div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="match-container text-left text-sm mb-0" style={{ backgroundColor: "rgb(244, 246, 246)" }}>
                        <div className="CombiPopup-styles-module-total">
                          <span>Total Odds:</span>
                          <div className="CombiPopup-styles-module-odds">{selectedMatch.totalOdds}</div>
                        </div>
                      </div>
                    </div>
                    <div className="hidden md:flex justify-center mt-4 mb-3">
                      <button
                        onClick={() => {
                          handleOdd();
                        }}
                        className="bg-combisColor w-11/12  h-12 text-white px-4 py-2 rounded"
                      >
                        Add Bet to Coupon
                      </button>
                    </div>
                    <div className="md:hidden flex justify-center mt-4 mb-3">
                      <button
                        className={`px-4 py-2  disabled:cursor-not-allowed`}
                        onClick={() => {
                          prevCombis();
                        }}
                        disabled={selectedIndex <= 0}
                      >
                        <img src={leftarrow}></img>
                      </button>
                      <button
                        onClick={() => {
                          handleOdd();
                        }}
                        className="bg-combisColor w-11/12  h-12 text-white px-4 py-2 rounded"
                      >
                        Add Bet to Coupon
                      </button>
                      <button
                        className={`px-4 py-2  disabled:cursor-not-allowed`}
                        onClick={() => {
                          nextCombis();
                        }}
                        disabled={selectedIndex >= dataLen - 1}
                      >
                        <img src={rightarrow} alt="Next" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
