import { memo, useCallback, useEffect, useState } from "react";
import { LockButton } from "./LockButton";
import { useHandleOddsClick } from "../utility/handleOdd";
import { useSelector } from "react-redux";

export const GenericOddButtons = memo(({ type, matchId, home_team_name, away_team_name, selectedOddsIds }) => {
  const [bgColors, setBgColors] = useState({});
  const [previousValues, setPreviousValues] = useState({});

  const oddList = useSelector((state) => state?.[type]?.odds);

  const availableOdds = useSelector((state) => state?.[type]?.availableOddsMap);

  useEffect(() => {
    const newValues = selectedOddsIds.reduce((acc, oddId) => {
      const oddData = Object.values(oddList[matchId + "_" + oddId] ?? []);
      oddData.forEach((odd) => {
        if (odd.id !== undefined) acc[odd.id] = odd.value;
      });
      return acc;
    }, {});

    const updatedIds = Object.keys(newValues).filter((id) => {
      const oldValue = previousValues[id];
      const newValue = newValues[id];
      return oldValue !== undefined && oldValue !== newValue;
    });

    updatedIds.forEach((id) => {
      const oldValue = previousValues[id];
      const newValue = newValues[id];
      handleDataChange(id, oldValue > newValue ? "text-red-500" : "text-green-500");
    });
    setPreviousValues(newValues);
  }, [oddList, selectedOddsIds]);

  const handleDataChange = useCallback((id, color) => {
    setBgColors((prev) => ({ ...prev, [id]: color }));
    setTimeout(() => {
      setBgColors((prev) => ({ ...prev, [id]: undefined }));
    }, 5000);
  }, []);

  return selectedOddsIds.map((oddId) => {
    const oddData = Object.values(oddList[matchId + "_" + oddId] ?? []);
    const availableOdd = availableOdds?.[oddId + ""];
    const minLineValue =
      availableOdd?.isHaveLine && oddData.length > 0 ? oddData.map((item) => item.line).sort((a, b) => a - b)[0] : "";
    const filteredData = availableOdd?.isHaveLine ? oddData.filter((item) => item.line === minLineValue) : oddData;

    const captionsLength = availableOdd?.captions.length;

    const oddButtonMap = filteredData.slice(0, captionsLength);

    var count = captionsLength + (availableOdd?.isHaveLine ? 1 : 0);
    return (
      <div key={matchId + "_" + oddId} className={`col-span-1 grid grid-cols-${count} gap-1`}>
        <>
          {availableOdd?.isHaveLine && (
            <span className="text-xs font-normal min-w-5 items-center text-gray-500 flex col-span-1 justify-center text-right">
              {minLineValue}
            </span>
          )}
          {availableOdd?.captions.map((captionsName) => {
            const [i] = oddButtonMap.filter((e) => e.name === captionsName);
            if (!i || i === null) {
              return <LockButton key={captionsName} />;
            }

            return (
              <GenericOddButton
                key={matchId + "_" + oddId + "_" + captionsName}
                matchId={matchId}
                oddId={i.id}
                line={i.line}
                market_name={i.market_name}
                market_id={i.market_id}
                outcome_id={i.outcome_id}
                status={i.status}
                name={i.name}
                value={i.value}
                home_team_name={home_team_name}
                away_team_name={away_team_name}
                textColor={bgColors[i.id]}
              />
            );
          })}
        </>
      </div>
    );
  });
});

export const GenericOddButton = memo(
  ({
    matchId,
    oddId,
    line,
    market_name,
    market_id,
    outcome_id,
    status,
    name,
    value,
    home_team_name,
    away_team_name,
    textColor,
  }) => {
    const handleOdd = useHandleOddsClick();
    const [isSelected, setIsSelected] = useState(false);
    const bets = useSelector((state) => state?.homereducer.odds);
    useEffect(() => {
      const bet = bets.filter((e) => e.id === matchId + "-" + market_id + "-" + outcome_id);
      setIsSelected(bet !== null && bet.length !== 0);
    }, [bets]);
    if (status !== 0) {
      return <LockButton />;
    }
    return (
      <div
        className={`font-custom text-gray-900 flex h-[32px] md:h-9 text-center text-sm font-semibold justify-center rounded-custom items-center border 
        ${
          isSelected ? "bg-focusBg" : "bg-newButton md:bg-buttonbg"
        } border-gray-300 bg-buttonbg py-2 cursor-pointer col-span-1 relative`}
        onClick={() => {
          handleOdd({
            match: {
              id: matchId,
              home_team_name: home_team_name,
              away_team_name: away_team_name,
            },
            odd: {
              market_id: market_id,
              id: oddId,
              name: name,
              value: value,
              line: line,
              market_name: market_name,
              outcome_id: outcome_id,
            },
            isSelected,
          });
        }}
      >
        <span
          className={`${
            isSelected ? "text-white" : "text-oddColor"
          } block sm:hidden text-left text-xs flex-shrink-0 ml-2 font-normal text-gray-500`}
        >
          {name.length > 10 ? name.substring(0, 10) + "..." : name}
        </span>
        <span className={`${textColor}  ${isSelected ? "text-white" : ""} mx-auto text-center`}>{value}</span>
        {textColor === "text-green-500" && (
          <span
            className={`absolute text-gray-700 text-l ${textColor}`}
            style={{ right: "0", top: "15%", transform: "translateY(-50%)" }}
          >
            ◥
          </span>
        )}
        {textColor === "text-red-500" && (
          <span
            className={`absolute text-gray-700 text-l ${textColor}`}
            style={{ right: "0", top: "85%", transform: "translateY(-50%)" }}
          >
            ◢
          </span>
        )}
      </div>
    );
  }
);
