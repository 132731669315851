import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { initializeWebSocket, LiveMatchesApi } from "../../../store/actions/live_matches";
import { GenericMainPageBody } from "../../Generic/GenericBody";

export const LiveMatchesLanding = ({ showDetail }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      LiveMatchesApi({
        pageNumber: 1,
        pageSize: 15,
      })
    );
    const socket = dispatch(initializeWebSocket());
    return () => {
      if (socket && socket.close) {
        socket.close();
      }
    };
  }, [dispatch]);
  return (
    <GenericMainPageBody
      allEventsLink={"/live-betting"}
      type={"livematches"}
      showDetail={showDetail}
      title={"Live"}
      isLive={true}
    />
  );
};
