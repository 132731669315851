import { Top_Events } from "./Top_Events";
import { TopCombis } from "./TopCombis";
import { Last_Min } from "./Last_min.js";
import Main_page_text from "./Main_page_text.jsx";
import { useState } from "react";
import { FcAlarmClock } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { updateRequestBody } from "../../store/actions/homeTypes";
import { setSelectedItem } from "../../store/actions/sidebarActions";
import { LiveIcon } from "./liveIcons.js";
import { FaChevronRight } from "react-icons/fa";
import { calendar, combination, earth, german, football, basketball } from "../../assets/images";
import { bet_guide, carccashout, cashout, favorite } from "../../assets/images";

import { Link, useNavigate } from "react-router-dom";
import { LiveMatchesLanding } from "./LiveMatches/Live.js";

const today = new Date();
const dayOfMonth = today.getDate();

const quickLinks = [
  {
    icon: FcAlarmClock,
    title: "Live",
    value: "live-betting",
  },
  {
    icon: calendar,
    title: "Today's",
    value: "todays-matches",
  },
  {
    icon: combination,
    title: "Combis",
    value: "top-combis",
  },

  {
    icon: german,
    title: "German",
    value: "german-football",
  },
  {
    icon: earth,
    title: "European",
    value: "european-football",
  },
  {
    icon: earth,
    title: "European",
    value: "european-football",
  },
];

const mobileQuickLinks = [
  {
    icon: FcAlarmClock,
    title: "Live",
    value: "live-betting",
  },
  {
    icon: calendar,
    title: "Today's",
    value: "todays-matches",
  },
  {
    icon: football,
    title: "Football",
    value: "football",
  },
  {
    icon: combination,
    title: "Combis",
    value: "top-combis",
  },

  {
    icon: basketball,
    title: "Basketball",
    value: "basketball",
  },
];

export function MainPage({ showDetail }) {
  const dispatch = useDispatch();
  const onSelectionChange = (selection) => {
    dispatch(setSelectedItem(selection));
    dispatch(updateRequestBody(selection));
  };
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState(null);
  const topCompetitions = useSelector((state) => state?.topCompetition?.data);
  const TopCompetition = ({ data, onSelectionChange }) => {
    return (
      <ul>
        {data?.slice(0, 5).map((e, index) => (
          <li className="cursor-pointer mb-1 w-full" key={index}>
            {index === 0 && (
              <div className="flex items-center justify-between font-semibold text-lg  pb-2 border-b-2 border-gray-200">
                <span>Top Wettbewerbe</span>
                <div className="flex items-center pr-2 text-sm text-gray-800">
                  <Link to="/top-events" className="ml-1 flex items-center">
                    <span className="text-gray-800 font-semibold">Alle Wettens</span>
                    <FaChevronRight className="text-gray-400 ml-1 text-sm" />
                  </Link>
                </div>
              </div>
            )}
            <div className="" onClick={() => onSelectionChange([{ tournamentId: e.tournament_id }])}>
              <div className="flex  items-center gap-6">
                <div className="">
                  <img src={e.icon} alt="icon" className="w-5 h-5 rounded-xl" />
                </div>
                <div className="border-b flex w-[51vh]">
                  <div className="flex flex-col place-content-center h-10 mb-1">
                    <span className="text-md  md:text-sm md:mb-1">{e.group_name}</span>
                    <span className="text-xs text-gray-500">{e.group_name}</span>
                  </div>
                  <span className="flex items-center text-gray-600 ml-auto pr-2 text-xs md:text-sm md:mb-3">
                    {e.group_name.length}
                    <FaChevronRight className="ml-2 text-gray-400 text-sm" />
                  </span>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  const handleSelectItem = (item) => {
    dispatch(setSelectedItem(item));
    handleActiveMenu(item);
  };
  const handleActiveMenu = (menu) => {
    setActiveMenu(menu);
  };

  const activeMenuChange = (value) => {
    dispatch(setSelectedItem(value));
    setActiveMenu(value);
  };
  const isMobile = window.innerWidth <= 768;

  const links = isMobile ? mobileQuickLinks : quickLinks;
  // const showDetail = useSelector((state) => state?.homereducer?.showDetail);
  return (
    <>
      <div className="grid grid-cols-12 ">
        <div className={`grid grid-cols-1  grid-rows-auto col-span-12`}>
          <div className="w-[100%] ">
            <ul className="flex flex-row md:flex-col overflow-x-scroll space-x-0.5 md:space-x-0 md:space-y-2   md:hidden ">
              {quickLinks.map((row, index) => (
                <li
                  className={`hidden md:flex flex-col md:flex-row items-center md:rounded h-[70px] text-center place-content-center cursor-pointer p-1 hover:bg-white  w-full ${activeMenu === row.value ? " bg-white md:font-semibold" : "bg-white md:bg-transparent"
                    }`}
                  onClick={() => handleSelectItem(row.value)}
                  key={index}
                >
                  {row.value === "live-betting" ? (
                    <Link to="/live-betting" className="center ">
                      <LiveIcon className="md:mr-3 md:h-[20px]" />
                      <span className="text-xs md:text-sm  mt-4 text-center">{row.title}</span>
                    </Link>
                  ) : (
                    <div>
                      <img
                        className="hidden md:block placeholder:mx-auto md:mx-0 md:mr-3 w-[30px] h-[30px] mb-2 md:w-[20px] md:h-[20px]"
                        src={row.icon}
                        alt="icon"
                      />
                      <div className="relative md:hidden">
                        <img
                          className="mx-auto md:mx-0 md:mr-3 w-[30px] h-[30px] mb-2 md:w-[20px] md:h-[20px]"
                          src={row.icon}
                          alt="icon"
                        />
                        {row.value === "todays-matches" && (
                          <span className="absolute top-2 right-0.5 inset-0 flex items-center justify-center  font-bold text-sm">
                            {dayOfMonth}
                          </span>
                        )}
                      </div>

                      <span className="text-xs md:text-sm text-nowrap text-center">{row.title}</span>
                    </div>
                  )}
                </li>
              ))}
              {mobileQuickLinks.map((row, index) => (
                <li
                  className={`md:hidden flex flex-col md:flex-row items-center md:rounded h-[65px] text-center place-content-center cursor-pointer p-1 hover:bg-white  w-full ${activeMenu === row.value ? " bg-white md:font-semibold" : "bg-white md:bg-transparent"
                    }`}
                  onClick={() => {
                    if (row.value === "football") {
                      navigate("/footballMenus");
                      navigate("/footballMenus");
                    } else if (row.value === "basketball") {
                      navigate("/basketballMenus");
                      navigate("/basketballMenus");
                    } else {
                      handleSelectItem(row.value);
                      handleSelectItem(row.value);
                    }
                  }}
                  key={index}
                >
                  {row.value === "live-betting" ? (
                    <Link to="/live-betting" className="center">
                      <LiveIcon className="md:mr-3 md:h-[20px]" />
                      <span className="text-xs md:text-sm  mt-0 text-center">{row.title}</span>
                    </Link>
                  ) : (
                    <div>
                      <img
                        className="hidden md:block placeholder:mx-auto md:mx-0 md:mr-3 w-[30px] h-[30px] mb-0 md:w-[20px] md:h-[20px]"
                        src={row.icon}
                        alt="icon"
                      />
                      <div className="relative md:hidden">
                        <img
                          className="mx-auto md:mx-0 md:mr-3 w-[30px] h-[30px] mb-0 md:w-[20px] md:h-[20px]"
                          src={row.icon}
                          alt="icon"
                        />
                        {row.value === "todays-matches" && (
                          <span className="absolute top-2 right-0.5 inset-0 flex items-center justify-center  font-bold text-sm">
                            <span className="text-xs font-normal">{dayOfMonth}</span>
                          </span>
                        )}
                      </div>
                      <span className="text-xs md:text-sm text-nowrap text-center">{row.title}</span>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="row-span-1 bg-white p-6 pb-0 md:pb-6 pt-3 mt-2 md:mt-3 col-span-12 pr-0 md:hidden">
            <ul>
              <TopCompetition data={topCompetitions} onSelectionChange={onSelectionChange} />
            </ul>
          </div>
          <div className={`row-span-1  col-span-12  md:pr-7`}>
            <TopCombis showDetail={showDetail} />
          </div>
          <div className={`row-span-1  col-span-12 pr-0 md:pr-4`}>
            <Top_Events showDetail={showDetail} />
          </div>
          <div className={`row-span-1  col-span-12 md:pr-4`}>
            <Last_Min showDetail={showDetail} />
          </div>
          <div className={`row-span-1  col-span-12 md:pr-4`}>
            <LiveMatchesLanding showDetail={showDetail} />
          </div>

          <div className={`md:hidden row-span-1  col-span-12`}>
            <Features />
          </div>
          {/* <div className={`row-span-1  col-span-12 pr-4`}>
            <LiveBetting showDetail={showDetail} />
          </div> */}
          <div className={`row-span-1  col-span-12 pr-4`}>
            <Main_page_text />
          </div>
        </div>

        {/* {showDetail && (
          <div className="bg-custom sticky top-6 h-[20vh]  col-span-4">
            <DetailCard />
          </div>
        )} */}
      </div>
    </>
  );
}
const list = [
  {
    name: "Bet Guide",
    image: bet_guide,
  },
  {
    name: "Car ccashout",
    image: carccashout,
  },
  {
    name: "Favorite",
    image: favorite,
  },
  {
    name: "Cashout",
    image: cashout,
  },
];

const Features = () => {
  return (
    <div className="w-full  bg-white mt-2">
      {/* main tabs  */}
      <div className="flex justify-between pt-4 px-4 font-semibold">
        <div>Features</div>
        <Link to="/bet-guide" className="logo">
          <div className="flex items-center justify-end gap-1 py-1 cursor-pointer" onClick={() => { }}>
            <span className="text-black font-semibold">All</span>
            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="#9ca3af">
              <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
            </svg>
          </div>
        </Link>
      </div>

      {/* tabs div  */}
      <div className="flex gap-2 justify-between w-full px-3 pb-3 bg-white overflow-x-auto">
        <div className="flex flex-row gap-2">
          {list.map((item, index) => {
            return (
              <div
                key={index}
                className="flex w-full min-w-[275px] justify-start p-3 gap-2 items-center border border-gray-300 rounded-md bg-gray-100"
              >
                <img src={item.image} alt="f" className="w-10 h-10" />
                <div className="flex flex-col">
                  <span className="text-sm font-semibold">{item.name}</span>
                  <span className="text-xs text-gray-500">
                    Learn the basics of sports betting in just a few minutes.
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};