import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import routes from "./routes";
import "./App.css";
import DefaultLayout from "./app/Layout/DefaultLayout";
import MainLayout from "./app/Layout/MainLayout";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import LiveBattingLayout from "./app/Layout/LiveBattingLayout";
import UserLayout from "./app/Layout/UserLayout";
import BetGuideLayout from "./app/Layout/BetGuideLayout";
import { PersistGate } from "redux-persist/integration/react";
import PayoutLayout from "./app/Layout/PayoutLayout";
import MyBetsLayout from "./app/Layout/MyBetsLayout";
import RegisterLayout from "./app/Layout/RegisterLayout";
import NewsLayout from "./app/Layout/NewsLayout";
import SportLayout from "./app/Layout/AllSportLayout";
import MyBets2Layout from "./app/Layout/MyBets2Layout";
import FootballMenus from "./app/Layout/FootballLayout";
import BasketballMenus from "./app/Layout/BasketballLayout";

const useAuth = () => {
  const authToken = localStorage.getItem("authToken");
  return authToken ? JSON.parse(authToken) : { isAuthenticated: false };
};

const ProtectedRoute = ({ element: Element, ...rest }) => {
  const { isAuthenticated } = useAuth();
  // : location.pathname === "/userPage" ? (
  //   <UserLayout>
  //     <Element {...rest} />
  //   </UserLayout>
  // )
  const location = useLocation();

  return isAuthenticated ? (
    location.pathname === "/userPage" ? (
      <UserLayout>
        <Element {...rest} />
      </UserLayout>
    ) : location.pathname === "/meinWetten" ? (
      <MyBetsLayout>
        <Element {...rest} />
      </MyBetsLayout>
    ) : location.pathname === "/payout" ? (
      <PayoutLayout>
        <Element {...rest} />
      </PayoutLayout>
    ) : (
      <DefaultLayout>
        <Element {...rest} />
      </DefaultLayout>
    )
  ) : //buraya if sarti koymak istiyorum
  location.pathname === "/meinWetten" ? (
    <Navigate to="/my-bet" />
  ) : (
    <Navigate to="/login" />
  );
};

const PublicRoute = ({ element: Element, ...rest }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  return isAuthenticated && location.pathname === "/login" ? (
    <Navigate to="/" />
  ) : location.pathname === "/login" || location.pathname === "/register" ? (
    <RegisterLayout>
      <Element {...rest} />
    </RegisterLayout>
  ) : location.pathname === "/live-betting" ? (
    <LiveBattingLayout>
      <Element {...rest} />
    </LiveBattingLayout>
  ) : location.pathname === "/bet-guide" ? (
    <BetGuideLayout>
      <Element {...rest} />
    </BetGuideLayout>
  ) : location.pathname === "/payout" ? (
    <PayoutLayout>
      <Element {...rest} />
    </PayoutLayout>
  ) : location.pathname === "/meinWetten" ? (
    <MyBetsLayout>
      <Element {...rest} />
    </MyBetsLayout>
  ) : location.pathname === "/mobilDynamic" ? (
    <SportLayout>
      <Element {...rest} />
    </SportLayout>
  ) : location.pathname === "/footballMenus" ? (
    <FootballMenus>
      <Element {...rest} />
    </FootballMenus>
  ) : location.pathname === "/basketballMenus" ? (
    <BasketballMenus>
      <Element {...rest} />
    </BasketballMenus>
  ) : location.pathname === "/news" ? (
    <NewsLayout>
      <Element {...rest} />
    </NewsLayout>
  ) : location.pathname === "/my-bet" ? (
    <MyBets2Layout>
      <Element {...rest} />
    </MyBets2Layout>
  ) : (
    <MainLayout>
      <Element {...rest} />
    </MainLayout>
  );
};

const RouteWrapper = ({ element: Element, isProtected, ...rest }) => {
  if (isProtected) {
    return <ProtectedRoute element={Element} {...rest} />;
  } else {
    return <PublicRoute element={Element} {...rest} />;
  }
};
const queryClient = new QueryClient();
const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Routes>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <RouteWrapper
                      element={route.element}
                      isProtected={route.isProtected}
                    />
                  }
                />
              ))}
            </Routes>
          </Router>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
