export const LockButton = () => {
  return (
    <div className="flex justify-center h-[32px] md:h-9 bg-newButton md:bg-buttonbg rounded-custom items-center border md:shadow-md border-gray-300 py-2 cursor-not-allowed col-span-1">
      <span className="text-gray-700">
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="15" viewBox="0 0 11 15">
          <g fill="none" fillRule="evenodd" transform="translate(0 1)">
            <rect width="11" height="9" y="5" fill="#BBB" rx="1" />
            <path
              stroke="#BBB"
              strokeWidth="1.5"
              d="M3 6.106c-.017 0 4.543.003 5 0v-3.66C8 1.094 6.88 0 5.5 0S3 1.095 3 2.445v3.66z"
            />
          </g>
        </svg>
      </span>
    </div>
  );
};
