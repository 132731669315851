import { useSelector } from "react-redux";

export const LiveGenericOddNamesGroup = ({ className, selectedOddsIds, type, tournamentName }) => {
  const availableOdds = useSelector((state) => state?.[type]?.availableOddsMap);
  const showDetail = useSelector((state) => state?.homereducer?.showDetailLive);

  return (
    <div className={`${className} grid grid-cols-12 col-span-12 bg-customs`}>
      <div className="grid grid-cols-12 col-span-12">
        <div className={`${showDetail ? "col-span-4" : "col-span-5"}`}>
          {tournamentName && (
            <span className="flex items-center gap-2 text-xs w-[150%] md:w-full h-7 text-center pl-2">
              {tournamentName}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="12px"
                viewBox="0 -960 960 960"
                width="12px"
                fill="#676767"
              >
                <path d="M640-160v-280h160v280H640Zm-240 0v-640h160v640H400Zm-240 0v-440h160v440H160Z" />
              </svg>
            </span>
          )}
        </div>
        <div className={`${showDetail ? "col-span-8" : "col-span-7"} grid grid-cols-12 md:grid hidden`}>
          <div
            className={`col-span-11 grid grid-cols-${selectedOddsIds.length} gap-2 font-semibold text-xs text-gray-40`}
          >
            {selectedOddsIds.map((oddId) => {
              const availableOdd = availableOdds[oddId] || [];
              const captionsLength = availableOdd?.captions?.length;
              const count = captionsLength + (availableOdd?.isHaveLine ? 1 : 0);

              return (
                <div key={oddId} className={`col-span-1 grid grid-cols-${count} gap-1`}>
                  {availableOdd?.isHaveLine && <div className="col-span-1" />}
                  {availableOdd?.captions?.map((caption, idx) => (
                    <div key={idx} className="flex min-w-5 text-right justify-center items-center col-span-1">
                      <span className="text-xs font-semibold text-gray-400 items-center rounded-md py-2 w-8 flex justify-center">
                        {caption}
                      </span>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
